import React from 'react'
import {  Nav, NavItem, NavLink } from "reactstrap";

const AccountPanel = () => {
    return (
        <div>
            <a href="/account/profile">My Account</a>
            <Nav vertical>
                <NavItem>
                    <NavLink href="/account">Profile</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/account/password">Change Password</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#">Subscription</NavLink>
                </NavItem>
            </Nav>
        </div>
    )
}
export default AccountPanel