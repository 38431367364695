import React, { Component } from "react";
import { connect } from 'react-redux'
import { getFirebase } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom'

// reactstrap components
import { Button, Form, Input, Container, Row, Col, FormGroup, Label } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import FooterLanding from "components/Footers/FooterLanding.js";
import AccountPanel from "components/SidePanels/AccountPanel"

//actions
import { updatePassword } from 'store/actions/authActions'

class Password extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    newPassword2: '',
    showpassword: true,
    passwordMatch: true
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state);
    if (this.state.newPassword !== this.state.newPassword2)
      this.setState({
        passwordMatch: false
      })

    else {
      this.setState({
        passwordMatch: true
      })
      this.props.updatePassword(this.state);
    }
  }

  componentDidMount = () => {
    const firebase = getFirebase()
    var user = firebase.auth().currentUser;
    //console.log(user)
    this.setState({
      email: user.email,
      displayName: user.displayName,
      uid: user.uid
    })
  }


  refreshPage = () => {
    window.location.reload(true);
  }

  handleTogglePassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      showpassword: !prevState.showpassword
    }));
  }

  render() {
    //console.log(this.props)
    const { updateSuccess, auth, updateError } = this.props
    const { passwordMatch } = this.state

    //Route Guard
    if (!auth.uid) return <Redirect to='/' />

    if (updateSuccess) { this.refreshPage() }

    document.documentElement.classList.remove("nav-open");
    // React.useEffect(() => {
    //   document.body.classList.add("contact-page");
    //   window.scrollTo(0, 0);
    //   document.body.scrollTop = 0;
    //   return function cleanup() {
    //     document.body.classList.remove("contact-page");
    //   };
    // });
    // var user = firebase.auth().currentUser;
    //console.log(user)

    const errorMessage = (!passwordMatch) ? 'Passwords do not match' : updateError
    return (
      <>
        <Navbar fixed={true} />
        <div className="wrapper">
          <div className="main ">
            <div className="section section-gray">
              <Container >
                <Row>
                  <Col md="2" className="setting-panel">
                    <AccountPanel />
                  </Col>
                  <Col md="10" className="setting-content">
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="12">
                        <h2 className="title">My Password</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto " md="8">
                        <Form onSubmit={this.handleSubmit}>
                          <FormGroup className="row">
                            <Label htmlFor="oldPassword" sm="4">Current Password</Label>
                            <Col sm="8">
                              <Input id="oldPassword" type="password" value={this.state.oldPassword} onChange={this.handleChange} />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label htmlFor="newPassword" sm="4">New Password</Label>
                            <Col sm="8">
                              <Input id="newPassword" type="password" value={this.state.newPassword} onChange={this.handleChange} />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label htmlFor="newPassword2" sm="4">Confirm Password</Label>
                            <Col sm="8">
                              <Input id="newPassword2" type="password" value={this.state.newPassword2} onChange={this.handleChange} />
                            </Col>
                          </FormGroup>
                          {errorMessage ? <p className="text-danger">{errorMessage}</p> : null}
                          <FormGroup className="row">
                            <Col className="ml-auto mr-auto" sm="6">
                              <Button block className="btn-round" color="primary">Update</Button>
                            </Col>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <FooterLanding />
      </>
    );
  }
}

// export default Account;

// export default Academy
// const mapStateToProps = (state, props) => ({
//   firebase: state.firebase
// })

// export default compose(
//   connect(mapStateToProps)
// )(Account)

const mapStateToProps = (state) => {
  return {
    updateError: state.auth.updateError,
    updateSuccess: state.auth.updateSuccess,
    auth: state.firebase.auth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (creds) => dispatch(updatePassword(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Password);
