import React, { Component } from "react";
import { connect } from 'react-redux'
import { signUp } from 'store/actions/authActions'
import { Redirect } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import SignInSocial from "../components/auth/SocialSignIn"

class RegisterPage extends Component {
  state = {
    email: '',
    password: '',
    showpassword: true
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state);
    const newUser = {
      email: this.state.email,
      password: this.state.password
    }
    this.props.signUp(newUser)
  }

  handleTogglePassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      showpassword: !prevState.showpassword
    }));
  }

  render() {
    const { auth, authError } = this.props;
    
    var location = (this.props.location.state !== undefined) ? this.props.location.state.from : '/'
    if (auth.uid) return <Redirect to={location} />
    return (
      <div className="register-page full-screen" >
        <Navbar />
        <div className="wrapper">
          <div
            className="page-header"
            style={{
              backgroundImage:
                "url(https://media.swimtrading.com/images/wave.jpg)"
            }}
          >
            {/* <div
            className="page-header"
            style={{
              color: "#999"
            }}
          > */}
            <div className="filter" />
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                  <Card className="card-register">
                    <CardTitle className="text-center" tag="h3">
                      Register
                    </CardTitle>
                    <div className="social">
                      <SignInSocial />
                    </div>
                    <div className="division">
                      <div className="line l" />
                      <span>or</span>
                      <div className="line r" />
                    </div>
                    <Form className="register-form" onSubmit={this.handleSubmit}>
                      <Input id='email' placeholder="Email" type="text" onChange={this.handleChange} />
                      <div className="input-group" id="show_hide_password">
                        <Input id='password' placeholder="Password" className="form-control" type={this.state.showpassword ? "password" : "text"} onChange={this.handleChange} />
                        <div className="input-group-addon" onClick={this.handleTogglePassword}>
                          <i className={this.state.showpassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true" />
                        </div>
                        {authError ? <p className="text-danger">{authError}</p> : null}
                      </div>
                      <Button block className="btn-round btn-danger" color="default">
                        SIGN UP
                      </Button>
                    </Form>
                    <div className="login">
                      <p>
                        By Sign Up, I agree to {" "}
                        <a href="/privacy" >
                          SwimTrading Privacy Policy
                        </a>
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="demo-footer text-center">
              <h6>
                © {new Date().getFullYear()} Swim Trading. All rights reserved.
              </h6>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
