import React from 'react'
import courseTitle from 'functions/courseTitle'

const SideBarItem = ({ lesson, posts, userCourse, selectLesson, currentIndex }) => {
    // const lessonLink = "/course/foundation-stock-course/learn/" + lesson.itemId
    // const linkId = "sidebar_link_" + lesson.itemId

    const lessonTitle = courseTitle(posts[lesson.itemId].title)
    const progress = userCourse.progress

    const lessonIndex = progress.findIndex((p, key) => p.itemId === lesson.itemId)

    var status = progress[lessonIndex].completed ? "complete" : "incomplete"

    if (currentIndex === lessonIndex) status += " active"
    return (
        <li className={"section-item " + status} value={lessonIndex} onClick={selectLesson}>
            {/* <a className="item" data-no-turbolink="true" href={lessonLink} id={linkId}> */}
            <span className="status-container">
                <span className="status-icon"> &nbsp;</span>
            </span>
            <div className="title-container">
                <span className="lecture-icon">
                    <i className="fa fa-file-text"></i>
                </span>
                <span className="lecture-name">{lessonTitle}</span>
            </div>
            {/* </a> */}
        </li>
    )

}
export default SideBarItem