const initState = {
    EnrollSuccess: false
}

const userCourseReducer = (state = initState, action) => {

    switch (action.type) {
        case 'ENROLL_SUCCESS':
            //console.log('Enroll success')
            return {
                ...state,
                EnrollError: null,
                EnrollSuccess: action.slug
            }
        case 'ENROLL_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                EnrollError: action.err.message,
                EnrollSuccess: false
            }
        case 'RESET_ENROLL':
            //console.log('RESET_ENROLL')
            return {
                ...state,
                EnrollError: null,
                EnrollSuccess: false
            }
        case 'UPDATE_PROGRESS_SUCCESS':
            //console.log('Update Progress success')
            return {
                ...state,
                ProgressError: null,
                ProgressSuccess: true
            }
        case 'UPDATE_PROGRESS_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                ProgressError: action.err.message,
                ProgressSuccess: false
            }

        default:
            return state

    }
}

export default userCourseReducer