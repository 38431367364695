const initState = {
    subscribeSuccess: false
}

const stripeCustomersReducer = (state = initState, action) => {

    switch (action.type) {
        case 'ADD_STRIPECUSTOMER_SUCCESS':
            return {
                ...state,
                tokenSuccess: true,
                tokenError: null
            }
        case 'ADD_STRIPECUSTOMER_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                tokenSuccess: false,
                tokenError: action.err.message
            }
        case 'ADD_STRIPE_CHARGE_SUCCESS':
            //console.log(action)
            return {
                ...state,
                chargeSuccess: true,
                chargeId: action.resp.id,
                chargeError: null
            }
        case 'ADD_STRIPE_CHARGE_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                chargeSuccess: false,
                chargeError: action.err.message
            }
        case 'RESET_TOKEN_STATUS':
            return {
                ...state,
                tokenSuccess: null,
                tokenError: null
            }

        default:
            return state

    }
}

export default stripeCustomersReducer