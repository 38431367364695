import React, { Component } from "react";
import HTMLReactParser from 'html-react-parser';

// core components
import Banner from 'components/banner/Banner'

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

var images;
const lightboxClass = "react-expand"

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    htmlToReactWithReplace(content, auth) {
        const html = content;
        images = []
        const replace = domNode => {
            if (domNode.attribs) {
                var classs
                if (domNode.attribs.class)
                    classs = domNode.attribs.class.split(" ")
                if (domNode.attribs.id === 'banner') {
                    // Insert Banner
                    return auth.uid ? <></> : <Banner />;
                } else if (classs && classs.indexOf(lightboxClass) > -1) {
                    // Add Image to Lightbox
                    domNode.attribs.onClick = this.openLightbox
                    images.push(domNode.attribs["src"])
                }
            }
        };
        return HTMLReactParser(html, { replace });
    }
    openLightbox = ((e) => {
        const ind = images.indexOf(e.target.src)
        this.setState({ isOpen: true, photoIndex: ind })
    })

    render() {
        var { content, auth } = this.props

        const { photoIndex, isOpen } = this.state;
        return (<>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length
                        })
                    }
                />
            )}
            <div className="article-content">{this.htmlToReactWithReplace(content, auth)}</div>
        </>
        )
    }
}


export default Content