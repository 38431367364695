// Your web app's Firebase configuration
export const fbConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
};
export const academyConfig = [
    {
        category: "Latest",
        style: 1,
        maxPosts: 4
    },
    {
        category: "Columnist Insights",
        style: 2,
        maxPosts: false
    },
    {
        category: "For New Traders",
        style: 4,
        maxPosts: 3
    },
    {
        category: "Swim Trading Academy",
        style: 1,
        maxPosts: 3
    }
]

export const categoriesConfig = [
    {
        category: "Latest",
        color: "danger"
    },
    {
        category: "Columnist Insights",
        color: "info"
    },
    {
        category: "For New Traders",
        color: "success"
    },
    {
        category: "Swim Trading Academy",
        color: "warning"
    }
]
