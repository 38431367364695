import React, { Component } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
} from "reactstrap";
// core components
import swimtradinglogo from 'assets/img/STT.png'

class LearnNavbar extends Component {
  state = {
    navbarColor: "navbar-transparent",
    bodyClick: false,
    collapseOpen: false,
    fixed: false,
    path: "/"
  }

  updateNavbarColor = () => {
    //console.log("updateNavbarColor")
    const divisor = 4
    if (
      document.documentElement.scrollTop > window.innerHeight / divisor - 1 ||
      document.body.scrollTop > window.innerHeight / divisor - 1
    ) {
      this.setState({ navbarColor: "" });
    } else if (
      document.documentElement.scrollTop < window.innerHeight / divisor ||
      document.body.scrollTop < window.innerHeight / divisor
    ) {
      this.setState({ navbarColor: "navbar-transparent" });
    }
  };

  componentDidMount() {
    const { fixed } = this.props

    if (fixed) {
      window.removeEventListener("scroll", this.updateNavbarColor);
      this.setState({ navbarColor: "" });
    }
    else {
      window.addEventListener("scroll", this.updateNavbarColor);
      this.setState({ navbarColor: "navbar-transparent" });
    }


  }

  render() {
    //console.log(auth)
    const { course } = this.props
    return (<>
      {this.state.bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            this.setState({ bodyClick: false });
            this.setState({ collapseOpen: false });
          }}
        />
      ) : null}
      <Navbar color="default"
        className={classnames("fixed-top", this.state.navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <div className="navbar-translate">
          <NavbarBrand id="navbar-brand" to="/" tag={Link}>
            <img src={swimtradinglogo} height="60" className="d-inline-block align-top" alt="Swim Trading logo" />
          </NavbarBrand>
          <NavbarBrand className="course-title" href={"/course/" + course.slug}>
            {course.title}
          </NavbarBrand>
          {/* <UncontrolledTooltip placement="bottom" target="navbar-brand">
                  Swim Trading
            </UncontrolledTooltip> */}
          <button
            className="navbar-toggler"
            id="navigation"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              this.setState({ bodyClick: true });
              this.setState({ collapseOpen: true });
            }}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse navbar isOpen={this.state.collapseOpen}>
          <Nav className="ml-auto" navbar>
            {/* {links} */}
          </Nav>
        </Collapse>
      </Navbar>
    </>
    );
  }
}
export default LearnNavbar;
