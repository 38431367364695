export default function getUserCourse(userCourses, userId, courseId) {
    var userCourse, userCourseId
    if (userCourses) {
        // get userCourses
        const userCoursesIds = Object.keys(userCourses)
        userCourseId = userCoursesIds.find(userCoursesId => {
            return userCourses[userCoursesId].courseId === courseId && userCourses[userCoursesId].userId === userId
        })
        userCourse = userCourses[userCourseId]
    }
    return { userCourse: userCourse, userCourseId: userCourseId }
}
