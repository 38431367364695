
export default function getCatPosts(posts, catId, maxPosts) {
    if (posts && catId) {
        let p0_id = Object.keys(posts).filter((key) => posts[key].categories.includes(catId))

        var p0_list = []
        p0_id.forEach(function (id) {
            p0_list.push(posts[id]);
        });

        let sortedPosts = p0_list.sort((a, b) => {
            const aDate = new Date(a.date), bDate = new Date(b.date)
            if (aDate < bDate) return 1;
            if (aDate > bDate) return -1;
            return 0;
        })

        if (sortedPosts) {
            posts = maxPosts ? sortedPosts.slice(0, maxPosts) : sortedPosts
        }
    }
    return posts
}
