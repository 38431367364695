import React, { Component, createContext } from 'react';
import { connect } from 'react-redux'

export const AuthContext = createContext();

class AuthContextProvider extends Component {
    render() {
        return (
            <AuthContext.Provider value={{ ...this.props }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}
const mapStateToProps = (state) => {
    //console.log(state);
    return {
        auth: state.firebase.auth,
        users: state.firestore.data.users,
    }
}
export default connect(mapStateToProps)(AuthContextProvider);

// export default AuthContextProvider;