import React from 'react'

import { Link } from "react-router-dom";
import {
    NavItem,
    NavLink,
    Nav,
    Button
} from "reactstrap";

const SignedOutLinks = (props) => (
    <Nav navbar>
        {/* <NavItem>
            <NavLink target='_blank' href="https://t.me/swimtrading" > Telegram</NavLink>
        </NavItem> */}
        <NavItem>
            <NavLink target='_blank' href="https://www.swimtrading.com/school" > School</NavLink>
        </NavItem>
        <NavItem>
            <NavLink data-placement="bottom" to="/login" tag={Link}>Login</NavLink>
        </NavItem>
        <NavItem>
            {/* <NavLink className="btn-round" color="danger" to="/signup">Free Sign Up</NavLink> */}
            <Button className="btn-round" color="danger" to="/register" tag={Link}>Free Sign Up</Button>
        </NavItem>
    </Nav>
);

export default SignedOutLinks