const initState = {
    subscribeSuccess: false
}

const newsletterReducer = (state = initState, action) => {

    switch (action.type) {
        case 'SUBSCRIBE_SUCCESS':
            //console.log('Subscribe success')
            return {
                ...state,
                subscribeError: null,
                subscribeSuccess:true
            }
        case 'SUBSCRIBE_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                subscribeError: action.err.message
            }
        
        default:
            return state

    }
}

export default newsletterReducer