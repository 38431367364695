import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

// reactstrap components
import { Row, Col, Button } from "reactstrap";

// core components
import LearnNavbar from "../Navbars/LearnNavbar.js";
import LoadingPageHeader from 'components/Headers/LoadingPageHeader'
import SideBar from './learn/SideBar'
import Lesson from './learn/Lesson'

import getCourseAndUserCourseFromSlug from 'functions/getCourseAndUserCourseFromSlug'
import { updateProgress, resetEnroll } from 'store/actions/userCourseActions'

class CourseLearn extends Component {
  constructor(props) {
    super(props)
    this.selectLesson = this.selectLesson.bind(this)
    this.handleLessonArrow = this.handleLessonArrow.bind(this)
    this.resetProgress = this.resetProgress.bind(this)
  }

  state = {
    lessonId: null,
    currentIndex: undefined
  }
  selectLesson(e) {
    const currentIndex = (typeof e.target.value === "number") ? e.target.value : parseInt(e.target.value)
    this.setState({ currentIndex: currentIndex })
  }
  handleLessonArrow(e) {
    //console.log(e.target.id)
    const newIndex = (typeof e.target.value === "number") ? e.target.value : parseInt(e.target.value)
    var oldIndex
    if (e.target.id === "nextLesson") {
      oldIndex = newIndex - 1
    }
    else if (e.target.id === "prevLesson") {
      oldIndex = newIndex + 1
    }


    const { courses, userCourses, match, auth } = this.props
    var { userCourse, userCourseId } = getCourseAndUserCourseFromSlug(match.params.slug, courses, userCourses, auth)
    var progress = userCourse.progress.map((p, i) => {
      var newP = p
      if (i === oldIndex)
        newP = { ...p, completed: true }
      return newP
    })

    const updateParams = { userCourseId: userCourseId, progress: progress }
    this.props.updateProgress(updateParams)

    this.setState({ currentIndex: newIndex })

  }
  resetProgress() {
    const { courses, userCourses, match, auth } = this.props
    var { userCourse, userCourseId } = getCourseAndUserCourseFromSlug(match.params.slug, courses, userCourses, auth)
    var progress = userCourse.progress.map((p, i) => {
      var newP = { ...p, completed: false }
      return newP
    })

    const updateParams = { userCourseId: userCourseId, progress: progress }
    this.props.updateProgress(updateParams)

  }

  prevArrow(prevLessonId) {
    if (prevLessonId !== null)
      return (
        <>
          <Button value={prevLessonId} id="prevLesson" onClick={this.handleLessonArrow} className="item-link item-link--common--RP3fp next-and-previous--button--2BBCj" data-purpose="go-to-previous" aria-labelledby="go-to-previous-item" aria-describedby="go-to-previous-item" tabIndex="0" location="[object Object]" role="link">
            <span className="udi udi-angle-left"></span>
          </Button>
          <div className="sr-only" id="go-to-previous-item"></div>
        </>
      )
    else
      return <></>
  }
  nextArrow(nextLessonId) {
    if (nextLessonId)
      return (
        <>
          <Button value={nextLessonId} id="nextLesson" onClick={this.handleLessonArrow} className="item-link item-link--common--RP3fp next-and-previous--button--2BBCj" aria-labelledby="go-to-next-item" aria-describedby="go-to-next-item" tabIndex="0" data-purpose="go-to-next" location="[object Object]" role="link">
            <span className="udi udi-angle-right"></span>
          </Button>
          {/* <div class="next-and-previous--tooltip--2wOrG tooltip-new--tooltip--8AUwh" data-purpose="popper" id="go-to-next-item" style={{ "position": "absolute", "will-change": "transform", "top": "0px", "left": "0px", "transform": "translate3d(469px, 0px, 0px)" }} data-placement="left">39. Class project 06 – Colors</div> */}
          <div className="sr-only" id="go-to-next-item"></div>
        </>)
    else
      return <></>
  }
  componentDidUpdate() {
    const { courses, userCourses, match, auth } = this.props
    const { currentIndex } = this.state
    if (isLoaded(courses, userCourses)) {
      //console.log(userCourses)
      const { userCourse } = getCourseAndUserCourseFromSlug(match.params.slug, courses, userCourses, auth)
      //console.log(userCourse)
      if (currentIndex === undefined) {
        if (userCourse !== undefined) {
          //Get Lesson
          var currentProgressIndex = userCourse.progress.findIndex((p) => p.completed === false)
          //console.log(currentProgressIndex)
          if (currentProgressIndex === -1) currentProgressIndex = 0

          this.setState({ currentIndex: currentProgressIndex })
        }
        else
          this.setState({ currentIndex: -2 })
      }
    }
  }
  componentDidMount() {
    this.props.resetEnroll()
  }

  render() {
    // const Post = ({ posts, match })=>{
    const { courses, userCourses, match, auth, posts } = this.props
    //console.log(this.props)
    const { currentIndex } = this.state
    if (!isLoaded(courses, userCourses, posts, currentIndex)) {
      return <LoadingPageHeader />
    }
    //console.log(currentIndex)
    if (currentIndex === -2) return <Redirect to='/courses' />

    const { course, userCourse } = getCourseAndUserCourseFromSlug(match.params.slug, courses, userCourses, auth)

    const currentLessonId = userCourse.progress[currentIndex].itemId
    const lesson = posts[currentLessonId]

    //Get Prev & Next Lesson
    const prog = userCourse.progress

    const prevIndex = currentIndex > 0 ? currentIndex - 1 : null
    const nextIndex = currentIndex < (prog.length - 1) ? currentIndex + 1 : null
    return (<>
      <LearnNavbar fixed={true} course={course} />
      {/* <SideBar /> */}
      <Row className="course-content">
        <Col className="lesson-content" md="9">
          <div className="next-and-previous--container user-activity--hide-when-user-inactive">
            <div className="next-and-previous--crossbar">
              <div className="next-and-previous--link-container">
                {this.prevArrow(prevIndex)}
              </div>
              <div className="next-and-previous--link-container">
                {this.nextArrow(nextIndex)}
              </div>
            </div>
          </div>
          <Lesson lesson={lesson} />
        </Col>
        <SideBar md="3" userCourse={userCourse} course={course} posts={posts} currentIndex={currentIndex} selectLesson={this.selectLesson} resetProgress={this.resetProgress} />
      </Row>
    </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  posts: state.firestore.data.posts,
  courses: state.firestore.data.courses,
  userCourses: state.firestore.data.userCourses,
  firestore: state.firestore,
  auth: state.firebase.auth
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateProgress: (props) => dispatch(updateProgress(props)),
    resetEnroll: () => dispatch(resetEnroll())
  }
}
export default compose(
  firestoreConnect(({ match }) => [
    { collection: 'courses', where: ['slug', '==', match.params.slug] },
    { collection: 'userCourses' },
    { collection: 'posts' },
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(CourseLearn)