import decodeHtml from './decodeHtml'
export default function decodeContent(content) {
    const imgRegex = /<img .*? class="(.*)" src="(.*?)".*?>/gi;
    const figureRegex = /<figure(.*)style="(.*?)".*?>/gi;
    const internalhrefRegex = /href="https?:\/\/.*?content.swimtrading.com\/post\/(.*?)"/gi;
    const membershrefRegex = /https?:\/\/.*?members.swimtrading.com\/(.*?)/gi;
    return decodeHtml(content
        .replace(imgRegex, '<img class="wpImage $1" src="$2">')
        .replace(figureRegex, '<figure $1 >')
        .replace(internalhrefRegex, 'href="/post/$1"')
        .replace(membershrefRegex, '/$1')
    )
}