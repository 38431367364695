import React from "react";
// reactstrap components
import { Container } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import FooterLanding from "components/Footers/FooterLanding.js";

function ContactUs() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });
  return (
    <>
      <Navbar fixed={true} />
      <div className="main">
        <div className="section section-gray">
          <Container>
            <div class="legal_doc_title">Swim Trading Privacy Policy</div>
            <h6>At SwimTrading, we’re committed to protecting and respecting your privacy.</h6>
<p>This Policy explains when and why we collect personal information about people who visit our website, how we use it, the conditions under which we may disclose it to others and how we keep it secure.</p>
<p>We may change this Policy from time to time so please check this page occasionally to ensure that you’re happy with any changes. By using our website, you’re agreeing to be bound by this Policy.</p>
<p>Any questions regarding this Policy and our privacy practices should be sent by email to hello@swimtrading.com.</p>
<h6>How do we collect information from you?</h6>
<p>We obtain information about you when you use our website, for example, when you contact us about products and services, purchase a product online, view our free content or if you register to receive one of our weekly newsletters.</p>
<h6>What type of information is collected from you?</h6>
<p>The personal information we collect might include your name, address, email address, IP address, and information regarding what pages are accessed and when. If you purchase a product from us, your card information is not held by us. Your card information is collected by our third party payment processors, who specialize in the secure online capture and processing of credit/debit card transactions, as explained below.</p>
<h6>How is your information used?</h6>
<p>We may use your information to: process orders that you have submitted; to carry out our obligations arising from any contracts entered into by you and us; seek your views or comments on the services we provide; notify you of changes to our services; send you communications which you have requested and that may be of interest to you. These may include information about campaigns, promotions of our company’s goods and services. We review our retention periods for personal information on a regular basis. We will hold your personal information on our systems for as long as is necessary for the relevant activity, or as long as is set out in any relevant contract you hold with us.</p>
<h6>Who has access to your information?</h6>
<p>We will not sell or rent your information to third parties. We will not share your information with third parties for marketing purposes.</p>
<p>Third party product providers are providers we work in association with. We work closely with various third party product providers to bring you a range of quality and reliable products and services designed to meet the needs of our students.</p>
<p>When you are using our secure online payment pages, your payment is processed by a third party payment processor, who specializes in the secure online capture and processing of credit/debit card transactions. If you have any questions regarding secure transactions, please contact us. We may transfer your personal information to a third party as part of a sale of some or all of our business and assets to any third party or as part of any business restructuring or reorganization, or if we’re under a duty to disclose or share your personal data in order to comply with any legal obligation or to enforce or apply our terms of use or to protect the rights, property or safety of our supporters and customers. However, we will take steps with the aim of ensuring that your privacy rights continue to be protected.</p>
<h6>Your choices</h6>
<p>You have a choice about whether or not you wish to receive information from us. If you do not want to receive direct marketing communications from us about our exciting products and services, then you can email us to inform us of your choice. We will not contact you for marketing purposes by email, phone or text message if you have decided to opt out. You can change your marketing preferences at any time by contacting us by email: hello@swimtrading.com.</p>
<h6>How you can access and update your information</h6>
<p>The accuracy of your information is important to us. We’re working on ways to make it easier for you to review and correct the information that we hold about you. In the meantime, if you change email address, or any of the other information we hold is inaccurate or out of date, please email us at: hello@swimtrading.com. You have the right to ask for a copy of the information Swim Trading hold about you.</p>
<p>Security precautions in place to protect the loss, misuse or alteration of your information</p>
<p>When you give us personal information, we take steps to ensure that it’s treated securely. Credit or debit card detailsare  handled directly by Stripe, we do not store any of your details. When you are on a secure page, a lock icon will appear on the bottom of web browsers such as Microsoft Internet Explorer (can change this to Google Chrome to be more relevant).</p>
<p>Non-sensitive details (your email address etc.) are transmitted normally over the Internet, and this can never be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, we cannot guarantee the security of any information you transmit to us, and you do so at your own risk. Once we receive your information, we make our best effort to ensure its security on our systems. Where we have given (or where you have chosen) a password which enables you to access certain parts of our websites, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
<h6>Profiling</h6>
<p>We may analyze your personal information to create a profile of your interests and preferences so that we can contact you with information relevant to you. We may make use of additional information about you when it is available from external sources to help us do this effectively. We may also use your personal information to detect and reduce fraud and credit risk.</p>
<h6>Use of ‘cookies’</h6>
<p>Like many other websites, Swim Trading’s website uses cookies. ‘Cookies’ are small pieces of information sent by an organization to your computer and stored on your hard drive to allow that website to recognize you when you visit. They collect statistical data about your browsing actions and patterns and do not identify you as an individual. For example, we use cookies to store your country preference. This helps us to improve our website and deliver a better more personalized service.</p>
<p>It is possible to switch off cookies by setting your browser preferences. Turning cookies of may result in a loss of functionality when using our website.</p>
<h6>Links to other websites</h6>
<p>Our website may contain links to other websites run by other organizations. This privacy policy applies only to our website‚ so we encourage you to read the privacy statements on the other websites you visit. We cannot be responsible for the privacy policies and practices of other sites even if you access them using links from our website. In addition, if you linked to our website from a third party site, we cannot be responsible for the privacy policies and practices of the owners and operators of that third party site and recommend that you check the policy of that third party site.</p>
<h6>16 or Under</h6>
<p>We are concerned to protect the privacy of children aged 16 or under. If you are aged 16 or under‚ please get your parent/guardian’s permission beforehand whenever you provide us with personal information.</p>
<p>Transferring your information</p>
<p>As part of the services offered to you through this website, the information which you provide to us may be transferred to countries outside the European Union (“EU”). By way of example, this may happen if any of our servers are from time to time re-located. These countries may not have similar data protection laws to the UK. By submitting your personal data, you’re agreeing to this transfer, storing or processing. If we transfer your information outside of the EU in this way, we will take steps to ensure that appropriate security measures are taken with the aim of ensuring that your privacy rights continue to be protected as outlined in this Policy. If you use our services while you are outside the EU, your information may be transferred outside the EU in order to provide you with those services.</p>
<h5>Review of this Policy</h5>
<p>We keep this Policy under regular review. This Policy was last updated in April 2020.</p>



          </Container>
        </div>
      </div>
      <FooterLanding />
    </>
  );
}

export default ContactUs;
