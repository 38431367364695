/* eslint-disable no-lone-blocks */
import React, { Component } from 'react'
import { connect } from 'react-redux'
// reactstrap components
import { Button } from "reactstrap";

import { enrollCourse } from 'store/actions/userCourseActions'
import history from '../../history';
import generateUserCourseDetails from 'functions/generateUserCourseDetails'
import courseCompletionStatus from 'functions/courseCompletionStatus'
class CourseCtaButton extends Component {

    handleEnroll = (e) => {
        const { course, userId, location, enrollCourse, } = this.props
        e.preventDefault()

        if (!userId) {
            history.push({
                pathname: '/register',
                state: { from: location.pathname }
            })
        } else {
            if (course.price === 0) {
                const userCourseDetails = generateUserCourseDetails(userId, course)
                enrollCourse(userCourseDetails)
            } else {
                history.push({
                    pathname: '/checkout',
                    state: { from: location.pathname, product: course }
                })
            }
        }
    }
    render() {
        const { course, userCourse, size, className } = this.props
        //console.log("cta",this.props)
        const completed = courseCompletionStatus(userCourse)
        //console.log(completed)
        if (completed === -1) {
            if (course.price === 0)
                return <Button className={className} size={size} onClick={this.handleEnroll} color="danger">Enroll Now!</Button>
            else
                return <Button className={className} size={size} onClick={this.handleEnroll} color="danger">Purchase</Button>
        } else {
            const courseLink = "/course/" + course.slug + "/learn"
            const learnButton = completed ? 'Continue Learning' : 'Start Learning'
            return <Button className={className} size={size} color="success" href={courseLink}>{learnButton}</Button>
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        enrollCourse: (creds) => dispatch(enrollCourse(creds))
    }
}
export default connect(null, mapDispatchToProps)(CourseCtaButton)

// export default CourseCtaButton