const initState = {}

const userReducer = (state = initState, action) => {

    switch (action.type) {
        case 'UPDATE_MAILINGLIST_SUCCESS':
            alert('Your Subscriptions have been updated')
            return {
                ...state,
                updateMailingListSuccess: true
            }
        case 'UPDATE_MAILINGLIST_ERROR':
            console.log(action.error.message)
            return {
                ...state,
                updateMailingListSuccess: false
            }
        default:
            return state

    }
}

export default userReducer