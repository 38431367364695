const initState = {}

const postReducer = (state = initState, action) => {
    switch (action.type) {
        case 'INIT_SUCCESS':
            //console.log('Init Posts success');
            //console.log(action.posts);
            return action.posts;
        case 'INIT_ERROR':
            //console.log('Init Posts error');
            return state;
        default:
            return state;
    }
}

export default postReducer