import React, { Component } from "react";
import { connect } from 'react-redux'

// reactstrap components
import { Button, Form, Input, Container, Row, Col, Alert } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import FooterLanding from "components/Footers/FooterLanding.js";

import { contactEmail } from 'store/actions/functionActions'

class ContactUs extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  }
  componentDidUpdate() {
    // this.props.contactEmailStatus
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("call function contactEmail:" + JSON.stringify(this.state));
    this.props.contactEmail(this.state);
  }

  render() {
    console.log(this.state)
    return (
      <div className="contact-page">
        <Navbar fixed={true} />
        <div className="main">
          <div className="section section-gray">
            {this.props.contactEmailStatus === "Success"
              ?
              <Container>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Thank you for your enquiry, we will get back to you shortly!</h2>
                </Col>
              </Container>
              :
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">Get in touch with us</h2>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <h3 className="title">
                      <p>Find us on social networks</p>
                    </h3>
                    <Button className="btn-just-icon mr-1" color="twitter" href="https://t.me/swimtrading" target="_blank">
                      <i className="fa fa-telegram" />
                    </Button>
                    <Button className="btn-just-icon mr-1" color="facebook" href="https://www.facebook.com/swimtrading" target="_blank">
                      <i className="fa fa-facebook" />
                    </Button>
                    <Button className="btn-just-icon mr-1" color="instagram" href="https://www.instagram.com/swimtrading/" target="_blank">
                      <i className="fa fa-instagram" />
                    </Button>
                    {/* <Button className="btn-just-icon" color="youtube" href="">
                      <i className="fa fa-youtube" />
                    </Button> */}
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="6">
                    <h3 className="title">
                      <p>Or drop us a note</p>
                    </h3>
                    <Form className="contact" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col md="6">
                          <Input id="firstName" placeholder="First Name" type="text" value={this.state.firstName} onChange={this.handleChange} required />
                        </Col>
                        <Col md="6">
                          <Input id="lastName" placeholder="Last Name" type="text" value={this.state.lastName} onChange={this.handleChange} required />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Input id="email" placeholder="Email" type="email" value={this.state.email} onChange={this.handleChange} required />
                        </Col>
                        <Col md="6">
                          <Input id="subject" placeholder="Subject" type="text" value={this.state.subject} onChange={this.handleChange} required />
                        </Col>
                      </Row>
                      <Input id="message" placeholder="Message" rows="7" type="textarea" value={this.state.message} onChange={this.handleChange} required />
                      {this.props.contactEmailStatus === "Error" ? <Alert color="danger">Something went wrong!</Alert> : null}
                      <Row>
                        <Col className="ml-auto mr-auto" md="6">
                          <Button block className="btn-round" color="primary">
                            Send
                      </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Container>
            }
          </div>
        </div>
        <FooterLanding />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contactEmailStatus: state.function.contactEmailStatus,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    contactEmail: (details) => dispatch(contactEmail(details)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
