import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/css/custom.css";
import "assets/css/video-js.min.css"

// pages
// import Index from "views/Index.js";
// import NucleoIcons from "views/NucleoIcons.js";
// import Sections from "views/Sections.js";
// import Presentation from "views/Presentation.js";
// import AboutUs from "views/examples/AboutUs.js";
// import AddProduct from "views/examples/AddProduct.js";
import Post from "views/Post.js";
import Category from "views/Category.js";
// import BlogPosts from "views/examples/BlogPosts.js";
// import Discover from "views/examples/Discover.js";
// import Ecommerce from "views/examples/Ecommerce.js";
import Error404 from "views/Error404.js";
// import Error422 from "views/examples/Error422.js";
// import Error500 from "views/examples/Error500.js";
// import LandingPage from "views/LandingPage.js";
import LoginPage from "views/LoginPage.js";
// import ProductPage from "views/examples/ProductPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views//RegisterPage.js";
// import MaintenancePage from "views//MaintenancePage.js";
// import SearchWithSidebar from "views/examples/SearchWithSidebar.js";
// import Settings from "views/examples/Settings.js";
// import TwitterRedesign from "views/examples/TwitterRedesign.js";
// import Academy from "views/Academy.js";
import Courses from "views/Courses.js";
import CourseInfo from 'components/courses/CourseInfo'
import CourseAction from 'components/courses/CourseAction'
import CourseLearn from 'components/courses/CourseLearn'
import ContactUs from "views/ContactUs.js";
import MarketAnalysis from "views/MarketAnalysis.js";
// import Account from "views/Account.js";
import Profile from "components/Account/Profile"
import Password from "components/Account/Password"
import PositionSizing from "views/PositionSizing.js";
import Privacy from "views/Privacy.js";
import Event from "views/Event.js";
import Checkout from "views/Checkout.js"
import CheckoutSuccess from "views/CheckoutSuccess.js"

// core components
//pages
// import LandingPage from "views/pages/LandingPage.js";
// import SignUpPage from "views/pages/SignUpPage.js";
// import LoginPage from "views/pages/LoginPage.js";

// import ApolloClient from 'apollo-boost'
// import { ApolloProvider } from 'react-apollo'

// const client = new ApolloClient({
//   uri: "https://academy.swimtrading.com/graphql"
// })
import AuthContextProvider from './contexts/AuthContext';
import history from './history';

function App() {

  return (
    <Router history={history}>

      <div className="App">

        <AuthContextProvider>
          <Switch>
            <Route exact path="/" component={Courses} />
            <Route path="/contactus" component={ContactUs} />
            <Route path="/account/profile" component={Profile} />
            <Route path="/account/password" component={Password} />
            <Redirect exact from="/account" to="/account/profile" />
            <Route path="/positionsizing" component={PositionSizing} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/event" component={Event} />
            <Route path="/login" component={LoginPage} />
            <Route path='/category/:slug' component={Category} />
            <Route path='/post/:slug' component={Post} />
            <Route path="/register" component={RegisterPage} />
            <Route exact path='/course/:slug/info' component={CourseInfo} />
            <Route exact path='/course/:slug' component={CourseAction} />
            <Route path='/course/:slug/learn' component={CourseLearn} />
            <Redirect exact path="/courses" to="/" />
            <Route path="/checkout" component={Checkout} />
            <Route path="/checkoutsuccess" component={CheckoutSuccess} />
            <Route path="/marketanalysis" component={MarketAnalysis} />
            <Route path='*' component={Error404} />
          </Switch>
        </AuthContextProvider>
      </div>
    </Router>
  );
}

export default App;
