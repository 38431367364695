import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'

import LoadingPageHeader from 'components/Headers/LoadingPageHeader'
import history from 'history.js';

import getUserCourse from 'functions/getUserCourse'

import generateUserCourseDetails from 'functions/generateUserCourseDetails'
import { enrollCourse } from 'store/actions/userCourseActions'


class CourseInfo extends Component {
    handleEnroll = () => {
        var { courses, userCourses, match, auth, enrollCourse, location, EnrollSuccess } = this.props
        if (isLoaded(courses, userCourses)) {
            if (EnrollSuccess)
                history.push({
                    pathname: "/course/" + EnrollSuccess + "/learn",
                    state: { from: location.pathname, resetEnrollFlag: true }
                })

            const courseKey = Object.keys(courses).find((key) => courses[key].slug === match.params.slug)
            const course = courses[courseKey]

            if (!auth.uid) {
                history.push({
                    pathname: '/register',
                    state: { from: location.pathname }
                })
            } else {
                const { userCourse } = getUserCourse(userCourses, auth.uid, courseKey)
                if (!userCourse) {
                    if (course.price === 0) {
                        const userCourseDetails = generateUserCourseDetails(auth.uid, course)
                        enrollCourse(userCourseDetails)
                    } else {
                        history.push({
                            pathname: '/checkout',
                            state: { from: location.pathname, product: course }
                        })
                    }
                }else{
                    history.push({
                        pathname: "/course/" + course.slug + "/learn",
                        state: { from: location.pathname, resetEnrollFlag: true }
                    })
                }
            }
        }
    }
    componentDidUpdate() {
        this.handleEnroll()
    }

    render() {
        return <LoadingPageHeader />
    }
}

const mapStateToProps = (state, props) => ({
    courses: state.firestore.data.courses,
    userCourses: state.firestore.data.userCourses,
    firestore: state.firestore,
    auth: state.firebase.auth,
    EnrollSuccess: state.userCourse.EnrollSuccess
})

const mapDispatchToProps = (dispatch) => {
    return {
        enrollCourse: (creds) => dispatch(enrollCourse(creds))
    }
}
export default compose(
    firestoreConnect(({ match }) => [
        { collection: 'courses', where: ['slug', '==', match.params.slug] },
        { collection: 'userCourses' },
        // { collection: 'posts' },
    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(CourseInfo)