import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import history from '../history';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { addStripeToken, resetTokenStatus, addStripeCharge } from 'store/actions/stripeCustomerActions'
import { enrollCourse } from 'store/actions/userCourseActions'
// reactstrap components
import { Row, Col, Button, Container, Table, Form, FormGroup, Input, Label, Modal } from "reactstrap";
// import CheckoutForm from "components/Checkout/CheckoutForm"

// core components
import CheckoutNavbar from "components/Navbars/CheckoutNavbar";
import FooterLanding from "components/Footers/FooterLanding.js";
import InjectedNewCardForm from "components/Checkout/InjectedNewCardForm"
import generateUserCourseDetails from 'functions/generateUserCourseDetails'
import numToCurr from "functions/numToCurr"

import PaymentHeader from "components/Headers/PaymentHeader"
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_stripePublishableKey);

class Checkout extends Component {

  state = {
    chargeAmount: 0,
    discount: 0,
    promocode_input: undefined,
    promocode: undefined,
    promocodeError: null,
    promocodeClass: ["row"],
    newCardModal: false,
    source: undefined
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      const { product } = this.props.location.state
      this.setState({ chargeAmount: product.price })
    }
  }
  componentDidUpdate(prevProps) {
    const { product } = this.props.location.state
    const { chargeAmount, discount, newCardModal } = this.state
    if (chargeAmount !== (product.price - discount))
      this.setState({ chargeAmount: product.price - discount })


    // Find the new token
    const {
      tokenSuccess, resetTokenStatus, //for token
      chargeId, stripeCustomerCharges, auth, EnrollSuccess, enrollCourse //for Enroll
    } = this.props

    //Token
    if (prevProps.tokenSuccess !== true && tokenSuccess === true) {
      this.setState({ newCardModal: false })
    }
    if (newCardModal === false)
      resetTokenStatus()

    // //Enroll
    if (chargeId)
      if (stripeCustomerCharges[chargeId].status === "succeeded" && EnrollSuccess === false) {
        // Show Success, Divert to learn
        const userCourseDetails = generateUserCourseDetails(auth.uid, product)
        //console.log("enroll user to course", userCourseDetails)
        enrollCourse(userCourseDetails)
      }


  }

  showProccessingPayment = () => {
    return <PaymentHeader />
  }

  validPromoCode() {
    const { discount, promocode } = this.state
    const pCode = (promocode !== undefined) ? "(" + promocode + ")" : ""
    if (discount > 0)
      return <tr>

        <td className="td-name">
        </td>
        <td className="td-number">
        </td>
        <td className="td-number">

        </td>
        <td className="td-number">
          Promo Code {pCode}:{" "}
          <small>-$</small>
          {numToCurr(discount)}
        </td>
        <td className="td-actions">
        </td>
      </tr>
    else return
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.toUpperCase()
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // this.props.signIn(this.state);
    const { promocodes } = this.props
    const promocodeIds = Object.keys(promocodes)
    const currDate = new Date().getTime() / 1000;
    const validCode = promocodeIds.find((promocodeId) => {
      return ((promocodes[promocodeId].promocode === this.state.promocode_input) && (currDate < promocodes[promocodeId].endDate.seconds) && (currDate >= promocodes[promocodeId].startDate.seconds))
    })

    if (validCode) {
      const { product } = this.props.location.state
      const promo = promocodes[validCode]
      var discount
      if (promo.unit === '$') {
        discount = promo.discount
      } else if (promo.unit === '%') {
        discount = Math.ceil(promo.discount * product.price) / 100
      }
      if ((product.price - discount) < 0.5)
        discount = product.price - 0.5

      this.setState({
        promocode: this.state.promocode_input,
        discount: discount,
        promocodeError: null,
        promocodeClass: ["row"]
      })
    } else {

      this.setState({
        promocode: undefined,
        discount: 0,
        promocodeError: 'Invalid Code',
        promocodeClass: ["row", "has-danger"]
      })
    }

  }
  getCard = (stripeCustomerSources) => {
    if (stripeCustomerSources) {
      const sourceIds = Object.keys(stripeCustomerSources)
      return sourceIds.map((sourceId, key) => {
        const source = stripeCustomerSources[sourceId]
        return <div className="form-check-radio " onClick={() => this.setState({ source: source.fingerprint })} key={key}>
          <Label check>
            <Input defaultValue={"option" + key} id={"sourceRadio" + source.fingerprint} name="sourceRadio" type="radio" checked={this.state.source === source.fingerprint} readOnly />  <span className="form-check-sign"></span><h6>{source.brand + " **** " + source.last4}</h6>
          </Label>
        </div>
      })
    }
  }
  submitNewCharge = () => {
    const { auth, users, stripeCustomerSources } = this.props
    const { source, chargeAmount, promocode } = this.state
    const { product } = this.props.location.state
    const chargedetails = {
      userId: auth.uid,
      charge: {
        source: stripeCustomerSources[source].id,
        amount: parseInt(chargeAmount * 100),
        statement_descriptor_suffix: product.title.substring(0, 21),
        receipt_email: users[auth.uid].email,
        description: promocode,
      }
    }
    //console.log(chargedetails)
    this.props.addStripeCharge(chargedetails)
  }
  memberCheckout = (auth, stripeCustomerSources, source) => {
    if (auth.uid) {
      return <>
        <div className="sourceform">
          <h2 className="title">Payment</h2>
          <Row>
            <Col md="3">
              <h5>Select payment account</h5>
            </Col>
            <Col>
              {this.getCard(stripeCustomerSources)}
              <Button className="btn-round" color="primary" type="button" onClick={() => this.setState({ newCardModal: true })} > + Add New Card</Button><br /><br />
            </Col>
            <Col md="4">
              <Button onClick={this.submitNewCharge} id="order-button" className="btn-round" color="danger" size="lg">Place Order</Button></Col>
          </Row>
        </div>
        <br />
        <div>
          {/* {{ newCharge.error }} */}
        </div></>
    } else {
      return <>Guest Checkout</>
    }
  }
  render() {

    //Route Guard
    const { auth, stripeCustomerSources, tokenError, stripeCustomerCharges, chargeId, EnrollSuccess, location } = this.props
    // if (!location.state) return <Redirect to='/' />
    if (!auth.uid)
      history.push({
        pathname: "/register",
        state: { from: location.pathname, product: location.state.product }
      })
    const { product } = location.state
    const { chargeAmount, promocodeClass, source } = this.state

    //console.log(product)
    if (chargeId) {
      //console.log("chargeStatus", stripeCustomerCharges[chargeId].status, EnrollSuccess)
      if (stripeCustomerCharges[chargeId].status === "succeeded") {
        if (EnrollSuccess) {
          //console.log("go to learning")
          history.push({
            pathname: "/course/" + EnrollSuccess + "/learn",
            state: { from: location.pathname, resetEnrollFlag: true }
          })
        }
      }

      if (stripeCustomerCharges[chargeId].status === undefined || stripeCustomerCharges[chargeId].status === "succeeded") {
        // show processing order
        return <PaymentHeader />
      }
    }


    return (<>
      <CheckoutNavbar fixed={true} />

      <Elements stripe={stripePromise}>
        <div className="wrapper">
          <div className="main">

            <Container>

              <h2 className="title">Products Ordered</h2>
              <Table className="table-shopping" responsive>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Qty</th>
                    <th className="text-right">Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>

                    <td className="td-name">
                      {product.title}
                      <br></br>
                      <small>
                        {product.excerpt}</small>
                    </td>
                    <td className="td-number">
                      <small>$</small>
                      {numToCurr(product.price)}
                    </td>
                    <td className="td-number">                      1
   </td>
                    <td className="td-number">
                      <small>$</small>
                      {numToCurr(product.price)}
                    </td>
                    <td className="td-actions">
                      {/* <Button className="btn-round btn-icon btn-neutral" color="default" data-placement="left" id="tooltip472286037" type="button" >
                        <i className="nc-icon nc-simple-remove"></i>
                      </Button>
                      <UncontrolledTooltip delay={0} placement="left" target="tooltip472286037">
                        Remove item
              </UncontrolledTooltip> */}
                    </td>
                  </tr>
                  {this.validPromoCode()}
                  <tr>
                    <td className="td-name">
                    </td>
                    <td className="td-number">
                    </td>
                    <td className="td-number">
                    </td>
                    <td className="td-number">
                      Order Total:{" "}
                      <small>$</small>
                      {numToCurr(chargeAmount)}
                    </td>
                    <td className="td-actions">
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/*-------------------------------------------------- Promo Code --------------------------------------------------*/}
              <Form onSubmit={this.handleSubmit}>
                <FormGroup className={promocodeClass.join(' ')} >
                  <Label htmlFor="promocode_input" md="2"> Promo Code: </Label>
                  <Col md="3">
                    <Input id="promocode_input" type="text" onChange={this.handleChange} className="form-control-danger" ></Input><br />
                    {this.state.promocodeError ? <p className="text-danger">{this.state.promocodeError}</p> : null}
                  </Col>
                  <Col md="3">
                    <Button color="primary" type="submit" className="btn-round"> Apply </Button>
                  </Col>
                </FormGroup>
              </Form>

              {/*-------------------------------------------------- Payment Method --------------------------------------------------*/}
              <div className="payment-section">
                <p><small>This is a secure 128-bit SSL encrypted payment. All transactions are performed through Stripe.</small></p>
                {this.memberCheckout(auth, stripeCustomerSources, source)}
              </div>

            </Container>
          </div>
        </div>
        <FooterLanding />
        {/*-------------------------------------------------- New Credit Card --------------------------------------------------*/}
        <Modal isOpen={this.state.newCardModal} toggle={() => this.setState({ newCardModal: false })} modalClassName="modal-register" >
          <div className="modal-header no-border-header text-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ newCardModal: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <h3 className="modal-title text-center">Add A New Credit Card</h3>
          </div>
          <div className="modal-body">
            <InjectedNewCardForm tokenError={tokenError} />
          </div>
        </Modal>
      </Elements>
    </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.firestore.data.users,
    auth: state.firebase.auth,
    promocodes: state.firestore.data.promocodes,
    stripeCustomerSources: state.firestore.data.stripeCustomerSources,
    stripeCustomerCharges: state.firestore.data.stripeCustomerCharges,
    tokenError: state.stripeCustomer.tokenError,
    tokenSuccess: state.stripeCustomer.tokenSuccess,
    chargeId: state.stripeCustomer.chargeId,
    EnrollSuccess: state.userCourse.EnrollSuccess,
    EnrollError: state.userCourse.EnrollError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addStripeToken: (props) => dispatch(addStripeToken(props)),
    addStripeCharge: (props) => dispatch(addStripeCharge(props)),
    resetTokenStatus: () => dispatch(resetTokenStatus()),
    enrollCourse: (creds) => dispatch(enrollCourse(creds))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      { collection: 'promocodes' },
      { collection: 'users' },
      {
        collection: 'stripeCustomers',
        doc: props.auth.uid ? props.auth.uid : undefined,
        subcollections: props.auth.uid ? [
          { collection: 'sources' }
        ] : undefined,
        storeAs: 'stripeCustomerSources'
      },
      {
        collection: 'stripeCustomers',
        doc: props.auth.uid ? props.auth.uid : undefined,
        subcollections: props.auth.uid ? [
          { collection: 'charges' }
        ] : undefined,
        storeAs: 'stripeCustomerCharges'
      },
    ]
  })
)(Checkout)