import React, { Component } from 'react'

// reactstrap components
import { Row, Col,Button } from "reactstrap";

import SideBarItem from './SideBarItem';

class SideBar extends Component {


    sectionBuilder = (section, sectionKey) => {
        const { selectLesson, currentIndex } = this.props

        return (
            <Row key={sectionKey}>
                <Col sm="12" >
                    <div className="heading section-title" >{section.title}</div>
                    <ul className="section-list">
                        {/* <li>No style</li> */}
                        {section.items.map((lesson, itemKey) => {
                            const props = {
                                lesson: lesson,
                                posts: this.props.posts,
                                userCourse: this.props.userCourse,
                                selectLesson: selectLesson,
                                currentIndex: currentIndex
                            }
                            return <SideBarItem {...props} key={itemKey} />
                        })}
                    </ul>
                </Col>
            </Row>
        )
    }

    render() {
        const { course, md,resetProgress } = this.props

        return (
            <Col md={md} className="sidebar course-section">
                {course.sections.map(this.sectionBuilder)}
                {/* <Row>
                    <Col> */}
                    <div className="heading section-title course-buttons" ><Button onClick={resetProgress}>Reset Progress</Button><Button href="/">Return</Button></div>
                {/* </Col>
                </Row> */}
            </Col>
        )
    }
}
export default SideBar