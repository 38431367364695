
import React from 'react'
// reactstrap components
import { Col, CardBody, CardTitle, Button, Progress } from "reactstrap";

// swimtrading components
import PriceTag from './PriceTag'
import CourseCtaButton from './CourseCtaButton'
import courseCompletionStatus from 'functions/courseCompletionStatus'

const CourseTitle = (props) => {
    let { userCourse, course } = props
    if (userCourse === undefined) {
        return (
            <div style={{display:"flex"}}>
                <CardTitle tag="h3">
                    <a href={course.url}> {course.title}</a>
                </CardTitle>
                <PriceTag price={course.price} />
            </div>
        )
    } else {
        return (
            <CardTitle tag="h3">
                <a href={course.url}> {course.title}</a>
            </CardTitle>
        )
    }
}
const CourseAction = (props) => {
    const { userCourse } = props
    const percentageCompleted = courseCompletionStatus(userCourse)

    if (percentageCompleted !== -1) {
        return <>
            <br />
            <Progress striped max={100} value={percentageCompleted} barClassName="progress-bar-primary" />
            <h5> {percentageCompleted}% Completed</h5><br />
        </>
    } else
        return <br />
}

const CourseButtons = (props) => {
    let { userCourse, course } = props
    if (userCourse === undefined) {
        return (
            <div className="text-center">
                < Button className="btn" color="danger" href={course.url} > More Info</ Button>
                {/* <div className="divider" />
                <CourseCtaButton {...props} /> */}
            </div>
        )
    } else
        return <div className="text-center"><CourseCtaButton {...props} /></div >
}

const CourseBody = (props) => {
    return (
        <Col md="12" >
            <CardBody>
                <CourseTitle {...props} />
                <CourseAction {...props} />
                <CourseButtons {...props} />
            </CardBody>
        </Col>
    )
}

export default CourseBody

