import {categoriesConfig} from '../config'

export default function categoryDetail(catName, key) {
    var catDetails = categoriesConfig.find((cat) =>
        cat.category === catName
    )
    return catDetails?catDetails[key]:null

}

