export const newSubscriber = ({ email, firstName }) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const kartraNewSubscriber = firebase.functions().httpsCallable('kartraNewSubscriber');
        kartraNewSubscriber({ email, firstName }).then((result) => {
            console.log(result)
            if (result.data.status === "Success")
                dispatch({ type: 'NEW_SUBSCRIBER_SUCCESS', result });
            if (result.data.status === "Error")
                dispatch({ type: 'NEW_SUBSCRIBER_ERROR', error: result.data });
        }).catch((error) => {
            dispatch({ type: 'NEW_SUBSCRIBER_ERROR', error });
        });

    }
}

export const contactEmail = (details) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const contactEmail = firebase.functions().httpsCallable('contactEmail');
        contactEmail(details).then((result) => {
            console.log(result)
            if (result.data.accepted.length > 0)
                dispatch({ type: 'CONTACTEMAIL_SUCCESS' });
            else
                dispatch({ type: 'CONTACTEMAIL_ERROR', error: { message: 'Something went wrong' } });
        }).catch((error) => {
            dispatch({ type: 'CONTACTEMAIL_ERROR', error });
        });

    }
}