
export const updateMailingList = ({ uid, mailingLists }) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        return firebase.firestore().collection('users').doc(uid).update({
            mailingLists
        }).then(() => {
            dispatch({ type: 'UPDATE_MAILINGLIST_SUCCESS' });
        }).catch(error => {
            dispatch({ type: 'UPDATE_MAILINGLIST_ERROR', error });
        });;

    }
}