import React from 'react'
import { Card, CardBody, CardTitle, Button } from "reactstrap";

const Banner = () => {
    return (
        <Card
            className="card-just-text card-plain banner"
            data-radius="none"
        >
            <CardBody>
                <h6 className="card-category">Best cards</h6>
                <CardTitle tag="h4">
                    Sales Banner
                    </CardTitle>
                <p className="card-description">
                    What all of these have in common is that they're pulling
                    information out of the app or the service and making it
                    relevant to the moment.
                    </p>
                <Button
                    className="btn-round"
                    color="warning"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                >
                    Get Started
                  </Button>
            </CardBody>
        </Card>
    )
}
export default Banner