
export const addStripeToken = (stripeDetails) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const { userId, token } = stripeDetails
        //console.log(stripeDetails)
        firebase.firestore().collection('stripeCustomers').doc(userId).collection('tokens').add({ token: token.id })
            .then(() => {
                dispatch({ type: 'ADD_STRIPECUSTOMER_SUCCESS' });
            }).catch((err) => {
                dispatch({ type: 'ADD_STRIPECUSTOMER_ERROR', err });
            });

    }
}

export const addStripeCharge = (chargeDetails) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        const { userId, charge } = chargeDetails
        //console.log(chargeDetails)
        firebase.firestore().collection('stripeCustomers').doc(userId).collection('charges').add(charge)
            .then((resp) => {
                dispatch({ type: 'ADD_STRIPE_CHARGE_SUCCESS', resp });
            }).catch((err) => {
                dispatch({ type: 'ADD_STRIPE_CHARGE_ERROR', err });
            });
    }
}


export const resetTokenStatus = () => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'RESET_TOKEN_STATUS' });
    }
}