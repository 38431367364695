import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'

import HTMLReactParser from 'html-react-parser';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import Navbar from "../Navbars/Navbar.js";
import CourseHeader from "components/Headers/CourseHeader.js";
import FooterLanding from "../Footers/FooterLanding.js";
import LoadingPageHeader from 'components/Headers/LoadingPageHeader'
import history from 'history.js';
import getUserCourse from '../../functions/getUserCourse'
import CourseCtaButton from 'components/courses/CourseCtaButton'


class CourseInfo extends Component {

    render() {
        // const Post = ({ posts, match })=>{
        var { courses, userCourses, match, auth, location, EnrollSuccess } = this.props

        if (EnrollSuccess)
            history.push({
                pathname: "/course/" + EnrollSuccess + "/learn",
                state: { from: location.pathname, resetEnrollFlag: true }
            })

        if (!isLoaded(courses, userCourses)) {
            return <LoadingPageHeader />
        }
        const courseKey = Object.keys(courses).find((key) => courses[key].slug === match.params.slug)
        const course = courses[courseKey]

        const { userCourse } = getUserCourse(userCourses, auth.uid, courseKey)
        //console.log(userCourse)

        //console.log(course.description)
        // const content = decodeContent(course.description)
        const content = (course.description)
        //console.log(content)
        return (<>
            <Navbar fixed={false} />
            <CourseHeader course={course} location={location} userCourse={userCourse} userId={auth.uid} />
            <div className="blog-post">
                {/* <BlogPostHeader /> */}
                <div className="wrapper">
                    <div className="main">
                        <div className="section section-white">
                            <Container>
                                <Row>
                                    <Col className="ml-auto mr-auto" md="12">
                                        <div className="article-content">{HTMLReactParser(content)}</div>
                                        <br />

                                    </Col>

                                </Row>
                                <div className="text-center">
                                    <CourseCtaButton size="lg" course={course} location={location} userId={auth.uid} userCourse={userCourse} />
                                </div>

                            </Container>
                        </div>
                    </div>
                </div>
                <FooterLanding />
            </div>
        </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    courses: state.firestore.data.courses,
    userCourses: state.firestore.data.userCourses,
    firestore: state.firestore,
    auth: state.firebase.auth,
    EnrollSuccess: state.userCourse.EnrollSuccess
})

export default compose(
    firestoreConnect(({ match }) => [
        { collection: 'courses', where: ['slug', '==', match.params.slug] },
        { collection: 'userCourses' },
        // { collection: 'posts' },
    ]),
    connect(mapStateToProps)
)(CourseInfo)