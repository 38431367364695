import React, { Component } from "react";
import { connect } from 'react-redux'
import { signIn, forgotPassword, resetAuthError } from 'store/actions/authActions'
import { Redirect } from 'react-router-dom'

// reactstrap components
import { Button, Card, CardTitle, Form, Input, Container, Row, Col, Modal, FormGroup } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import SignInSocial from "../components/auth/SocialSignIn"

class LoginPage extends Component {
  // document.documentElement.classList.remove("nav-open");
  // React.useEffect(() => {
  //   document.body.classList.add("login-page");
  //   document.body.classList.add("full-screen");
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("login-page");
  //     document.body.classList.remove("full-screen");
  //   };
  // });

  state = {
    email: '',
    password: '',
    resetEmail: '',
    resetPasswordModal: false
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state);
    this.props.signIn(this.state);
  }

  handleResetPassword = (e) => {
    e.preventDefault();
    this.props.forgotPassword(this.state.resetEmail);
    //console.log(this.props.function)
  }

  componentDidMount() {
    this.props.resetAuthError();
  }

  render() {
    const { authError, auth, resetSuccess } = this.props;
    //console.log(this.props)
    // const from = this.props.location.state.from
    var location = (this.props.location.state !== undefined) ? this.props.location.state.from : '/'
    if (auth.uid) return <Redirect to={location} />
    return (
      <div className="login-page full-screen">
        <Navbar />
        <div className="wrapper">
          <div
            className="page-header"
            style={{
              backgroundImage:
                "url(https://media.swimtrading.com/images/wave.jpg)"
            }}
          >
            {/* <div
            className="page-header"
            style={{
              color:"#999"
            }}
          > */}
            <div className="filter" />
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                  <Card className="card-register">
                    <CardTitle tag="h3">Welcome</CardTitle>
                    <div className="social">
                      <SignInSocial />
                    </div>
                    <div className="division">
                      <div className="line l" />
                      <span>or</span>
                      <div className="line r" />
                    </div>
                    <Form className="register-form" onSubmit={this.handleSubmit}>
                      {/* <label>Email</label> */}
                      <Input id='email' className="no-border" placeholder="Email" type="email" onChange={this.handleChange} />
                      {/* <label>Password</label> */}
                      <Input id='password' className="no-border" placeholder="Password" type="password" onChange={this.handleChange} />
                      {authError ? <p className="text-danger">{authError}</p> : null}
                      <Button block className="btn-round" color="danger">
                        login
                      </Button>
                    </Form>
                    <Button className="btn-link " color="danger" type="button" onClick={() => this.setState({ resetPasswordModal: true })} >Forgot password?</Button>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="demo-footer text-center">
              <h6>
                © {new Date().getFullYear()} Swim Trading. All rights reserved.
              </h6>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.resetPasswordModal} toggle={() => this.setState({ resetPasswordModal: false })} modalClassName="modal-register" >
          <div className="modal-header no-border-header text-center">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ resetPasswordModal: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <h3 className="modal-title text-center">Reset Password</h3>
          </div>
          <div className="modal-body">
            {resetSuccess ?
              <p className="text-danger">Check your email for you password reset link.</p>
              :
              <Form className="resetPassword-form" onSubmit={this.handleResetPassword}>
                <FormGroup>
                  <Input id='resetEmail' placeholder="Email" type="email" onChange={this.handleChange} />
                </FormGroup>{authError ? <p className="text-danger">{authError}</p> : null}
                <Button block className="btn-round" color="danger">Reset Password</Button>
              </Form>
            }

          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    resetSuccess: state.auth.resetSuccess,
    auth: state.firebase.auth
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    forgotPassword: (email) => dispatch(forgotPassword(email)),
    resetAuthError: () => dispatch(resetAuthError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
