import React, { Component } from 'react'

import decodeContent from 'functions/decodeContent'
import { Container } from "reactstrap"
import Content from "components/Post/Content.js"

class Lesson extends Component {
    onScroll(e) {
        document.querySelector('.next-and-previous--container').style.top = e.target.scrollTop + "px";
    }
    componentDidMount() {
        document.querySelector('.lesson-content').addEventListener("scroll", this.onScroll);
    }
    componentWillUnmount() {
        document.querySelector('.lesson-content').removeEventListener("scroll", this.onScroll);
    }
    componentDidUpdate() {
        const el = document.querySelector('.lesson-content')
        el.scrollTop = 0
        var video = document.getElementsByTagName("video");
        for (let e of video) { e.addEventListener("contextmenu", (e) => { e.preventDefault(); return false; }); }

    }


    render() {
        const { lesson } = this.props
        const content = lesson ? decodeContent(lesson.content) : ''
        return (
            <>
                <Container >
                    <div id="lessonContainer">
                        <Content content={content} />
                    </div>
                </Container>
            </>
        )
    }
}
export default Lesson