import React from 'react'
import formatMoney from '../../functions/formatMoney.js'
import { CardTitle, } from "reactstrap";

const FormatPrice = (price) => {
    //console.log(price)
    if (price === 0)
        return <font color="red">Free</font>
    else
        return formatMoney(price)
}
const PriceTag = ({ price }) => {
    return (<div className='ml-auto'>
        <CardTitle tag="h3">
            <strong >
                {FormatPrice(price)}
            </strong>
        </CardTitle>
    </div>
    )
}
export default PriceTag
