import React, { Component } from 'react'
import { Button, Form, Input, Container, Row, Col } from "reactstrap";

import { connect } from 'react-redux'
// import { subscribe } from 'store/actions/newsletterActions'
import { newSubscriber } from 'store/actions/functionActions'
import { AuthContext } from 'contexts/AuthContext';

class SubscribeForm extends Component {
    state = {
        email: '',
        firstName: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
        //console.log(this.state.email)
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validateEmail(this.state.email)) {
            this.setState({
                invalidEmail: false
            })
            this.props.newSubscriber(this.state);
        } else {
            this.setState({
                invalidEmail: true
            })
        }
        // console.log(this.props.firebase.functions());
        // var kartraNewSubscriber = this.props.firebase.functions().httpsCallable('kartraNewSubscriber')
        // const res = kartraNewSubscriber(this.state)
        // console.log(res)
    }
    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    subscriptionForm = (kartraSubscriber) => {
        if (kartraSubscriber && kartraSubscriber.status === "Success") {
            return (
                <Col className="ml-auto mr-auto" md="8">
                    <h2 className="text-center newsletter-head">Thank you for subscribing!</h2>
                </Col>)
        } else if (kartraSubscriber && kartraSubscriber.type === "244") {
            return (
                <Col className="ml-auto mr-auto" md="8">
                    <h2 className="text-center newsletter-head">You are already subscribed!</h2>
                </Col>)

        } else if (kartraSubscriber && kartraSubscriber.status === "Error") {
            return (
                <Col className="ml-auto mr-auto" md="8">
                    <h2 className="text-center newsletter-head">Something went wrong. Please drop us an email at support@swimtrading.com to let us know.</h2>
                </Col>)
        } else if (!kartraSubscriber) {
            return (
                <Col className="ml-auto mr-auto" md="8">
                    <h2 className="text-center newsletter-head">Like what you are reading?</h2>
                    {/* <Form className="newsletter-form" action='https://jaytun.us20.list-manage.com/subscribe/post' method="post" onSubmit={this.handleSubmit}> */}
                    <Form className="newsletter-form" onSubmit={this.handleSubmit}>
                        <input type="hidden" name="u" value="a602efed0d3eb0c00f73f02b5" />
                        <input type="hidden" name="id" value="77217f4424" />
                        <Row>
                            <Col md="3">
                                <Input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={this.state.firstName}
                                    placeholder="Name" onChange={this.handleChange} required />
                            </Col>
                            <Col md="6">
                                <Input
                                    type="email"
                                    name="EMAIL"
                                    id="email"
                                    value={this.state.email}
                                    placeholder="Email" onChange={this.handleChange} required />
                                {this.state.invalidEmail ? <p className="text-danger">Invalid Email</p> : null}
                            </Col>
                            <Col md="3">
                                <Button className="btn-fill" color="danger" >Update Me</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            )
        }
    }

    render() {
        const { kartraSubscriber } = this.props
        //console.log("subscribeSuccess = ", subscribeSuccess)
        return (<AuthContext.Consumer>{(authContext) => {

            //console.logog(authContext)
            const { auth } = authContext;
            if (!auth.uid)
                return (
                    <div className="section newsletter-section">
                        <Container>
                            <Row>
                                {this.subscriptionForm(kartraSubscriber)}
                            </Row>
                        </Container>
                    </div>)
        }
        }</AuthContext.Consumer>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        subscribeError: state.newsletter.subscribeError,
        subscribeSuccess: state.newsletter.subscribeSuccess,
        kartraSubscriber: state.function.kartraSubscriber
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        newSubscriber: (details) => dispatch(newSubscriber(details))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeForm);
