export const signUp = (newUser) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then(resp => {
            //console.log('t1')
            firebase.firestore().collection('users').doc(resp.user.uid).set({
                firstName: newUser.email.split('@')[0],
                email: newUser.email
            });
            firebase.firestore().collection('subscriptions').doc(resp.user.uid).set({
                subscribed: true,
                email: newUser.email
            });
        }).then(() => {
            return updateProfile({ displayName: newUser.email.split("@")[0] })
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' });
        }).catch((err) => {
            dispatch({ type: 'SIGNUP_ERROR', err });
        });
    }
}
export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: 'LOGIN_SUCCESS' });
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err });
        });

    }
}

export const forgotPassword = (email) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        
        firebase.auth().sendPasswordResetEmail(
            email
        ).then(function (user) {
            // alert('Please check your email...')
            dispatch({ type: 'PASSWORD_RESET_SUCCESS' });
        }).catch(function (err) {
            dispatch({ type: 'PASSWORD_RESET_ERROR', err });
        })
    }
}

export const signInSocial = (social) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        var provider

        switch (social) {
            case 'facebook':
                // code block
                provider = new firebase.auth.FacebookAuthProvider();
                break;
            case 'google':
                // code block
                provider = new firebase.auth.GoogleAuthProvider();
                break;
            default:
                let action = { err: { message: 'No Provider' } }
                dispatch({ type: 'SOCIAL_LOGIN_ERROR', action })
                return
            // code block
        }

        //console.log(provider.providerId.split(".")[0].toUpperCase())
        firebase.auth().signInWithPopup(provider)
            .then((resp) => {
                //console.log(resp)
                //Collect data for new users only
                if (resp.additionalUserInfo.isNewUser)
                    firebase.firestore().collection('users').doc(resp.user.uid).set({
                        firstName: resp.user.displayName.split(" ")[0],
                        lastName: resp.user.displayName.split(" ")[1],
                        initials: resp.user.displayName.split(" ")[0][0] + resp.user.displayName.split(" ")[1][0],
                        photoURL: resp.user.photoURL,
                        email: resp.user.email,
                        subscription: { newsletter: true }
                    })
                return
            }).then(() => {
                dispatch({ type: 'SOCIAL_LOGIN_SUCCESS' })
            }).catch((err) => {
                dispatch({ type: 'SOCIAL_LOGIN_ERROR', err })
            })
    }
}
export const linkSocial = (social) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        var provider

        switch (social) {
            case 'facebook':
                // code block
                provider = new firebase.auth.FacebookAuthProvider();
                break;
            case 'google':
                // code block
                provider = new firebase.auth.GoogleAuthProvider();
                break;
            default:
                let action = { err: { message: 'No Provider' } }
                dispatch({ type: 'SOCIAL_LOGIN_ERROR', action })
                return
            // code block
        }

        //console.log(provider.providerId.split(".")[0].toUpperCase())
        var user = firebase.auth().currentUser;
        user.linkWithPopup(provider).then(function (result) {
            // Accounts successfully linked.
            // var credential = result.credential;
            // var user = result.user;
            //console.log(credential)
            //console.log(user)
        }).then(() => {
            dispatch({ type: 'SOCIAL_LINK_SUCCESS' })
        }).catch(function (err) {
            // Handle Errors here.
            dispatch({ type: 'SOCIAL_LINK_ERROR', err })
        });


        // firebase.auth().signInWithPopup(provider)
        //     .then((resp) => {
        //console.log(resp)
        //         //Collect data for new users only
        //         if (resp.additionalUserInfo.isNewUser)
        //             firebase.firestore().collection('users').doc(resp.user.uid).set({
        //                 firstName: resp.user.displayName.split(" ")[0],
        //                 lastName: resp.user.displayName.split(" ")[1],
        //                 initials: resp.user.displayName.split(" ")[0][0] + resp.user.displayName.split(" ")[1][0],
        //                 photoURL: resp.user.photoURL,
        //                 email: resp.user.email,
        //                 subscription: { newsletter: true }
        //             })
        //     }).then(() => {
        //         dispatch({ type: 'SOCIAL_LOGIN_SUCCESS' })
        //     }).catch((err) => {
        //         dispatch({ type: 'SOCIAL_LOGIN_ERROR', err })
        //     })
    }
}

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({ type: 'LOGOUT_SUCCESS' });
        })
    }
}

export const updateProfile = ({ displayName, email, uid }) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        var user = firebase.auth().currentUser;
        user.updateEmail(email)
            .then(() => {
                return user.updateProfile({
                    displayName: displayName,
                    //   photoURL: "https://example.com/jane-q-user/profile.jpg"
                })
            }).then(() => {
                //console.log(uid)
                return firebase.firestore().collection('users').doc(uid).update({
                    firstName: displayName,
                    email: email
                });
            }).then(function () {
                // Update successful.
                dispatch({ type: 'UPDATE_PROFILE_SUCCESS' });
            }).catch(function (error) {
                // An error happened.
                dispatch({ type: 'UPDATE_PROFILE_ERROR', error });
            });
    }
}
export const updatePassword = ({ oldPassword, newPassword }) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        var user = firebase.auth().currentUser;
        return user.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, oldPassword))
            .then(function (e) {
                return user.updatePassword(newPassword)
            }).then(function () {
                // Update successful.
                dispatch({ type: 'UPDATE_PASSWORD_SUCCESS' });
            }).catch(function (error) {
                // An error happened.
                dispatch({ type: 'UPDATE_PASSWORD_ERROR', error });
            });
    }
}

export const resetAuthError = () => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'RESET_AUTH_ERROR' });
    }
}