export default function courseCompletionStatus(userCourse) {
    var percentageCompleted = -1
    if (userCourse) {
        const total = userCourse.progress.length
        var completed = 0;
        for (var i = 0; i < total; ++i) {
            if (userCourse.progress[i].completed === true)
                completed++;
        }
        percentageCompleted = Math.round(completed / total * 100)
    }
    return percentageCompleted
}