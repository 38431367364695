import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { signInGoogle ,signInFacebook} from '../../store/actions/authActions'
import { signInSocial} from '../../store/actions/authActions'
import googleicon from "../../assets/img/google-icon.svg"
// import { withFirebase } from '../../components/FirebaseContext'

import { Button, } from "reactstrap";

class SocialSignIn extends Component {

    handleSubmit = (e) => {
        let socialType = e.target.value
        //console.log(e.target)
        e.preventDefault();
        this.props.signInSocial(socialType)
    }

    render() {
        // const { auth } = this.props
        // const { authError } = this.props
        //console.log(this.props)
        
        return (
            <>
                <Button className="btn-block btn-round mr-1" color="facebook" type="submit" value="facebook" onClick = {this.handleSubmit}>
                    <i className="fa fa-facebook" /> facebook
                </Button>
                <Button className="btn-block btn-round mr-1 btn-border" color="google" type="submit" value="google"onClick = {this.handleSubmit}>
                    <img src={googleicon} alt="google icon" height="12" width="12" style={{ marginBottom: "4px" }} />{" "}google
                </Button>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        // auth: state.firebase.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        signInSocial: (socialType) => dispatch(signInSocial(socialType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialSignIn)
// export default withFirebase(SignInSocial);