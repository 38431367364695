import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
// reactstrap components
import { Form, Input, Container, Row, Col, Label } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import FooterLanding from "components/Footers/FooterLanding.js";

class PositionSizing extends Component {

  state = {
    accountbalance: 5000,
    riskpercentage: 2,
    risk: 0,
    units: 0,
    entryprice: '',
    stoploss: '',
    leverage: 1,
    direction: 1
  }
  handleChange = (e) => {

    this.setState({
      [e.target.id]: parseFloat(e.target.value)
    }, this.calculateRisk)
  }
  calculateRisk = () => {
    var { entryprice, stoploss, direction } = this.state
    //console.log(this.state)
    if (entryprice && stoploss) {
      var risk = direction * (entryprice - stoploss)
      this.setState({
        risk: risk >= 0 ? risk : "Invalid"
      }, this.calculateUnits)
    } else {
      this.setState({
        risk: 0
      })
    }
  }
  calculateUnits = () => {

    var { accountbalance, risk, riskpercentage } = this.state

    if (accountbalance && risk && riskpercentage) {
      this.setState({
        units: Math.floor(accountbalance * riskpercentage / 100 / risk)
      })
    }
    else
      this.setState({
        units: 0
      })
  }

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  }
  componentWillUnmount() {
    document.body.classList.remove("contact-page");
  }
  render() {

    //Route Guard
    const { auth } = this.props
    if (!auth.uid) return <Redirect to='/login' />

    return (
      <>
      <Navbar fixed={true}/>
        <div className="main">
          <div className="section section-gray">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Position Sizing Calculator</h2>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="12">
                  <Form className="contact" >
                    <Row>
                      <Col md="4">
                        <Label>Account Balance ($)</Label>
                        <Input id="accountbalance" placeholder="Account Balance" value={this.state.accountbalance} type="text" onChange={this.handleChange} />
                        <Label>Risk Percentage (%)</Label>
                        <Input id="riskpercentage" value={this.state.riskpercentage} type="number" step="1" min="1" max="100" onChange={this.handleChange} />
                        <Label>Direction</Label>
                        <Input id="direction" type="select" onChange={this.handleChange}>
                          <option value="1">Long</option>
                          <option value="-1">Short</option>
                        </Input>
                      </Col>
                      <Col md="4">
                        <Label>Entry Price</Label>
                        <Input id="entryprice" type="text" onChange={this.handleChange} />
                        <Label>Stop Loss</Label>
                        <Input id="stoploss" type="text" onChange={this.handleChange} />
                        <Label>Leverage ( X times )</Label>
                        <Input id="leverage" value={this.state.leverage} type="number" step="1" min="1" max="5" onChange={this.handleChange} />
                      </Col>
                      <Col md="4">
                        <Label>Stop Loss Budget</Label>
                        <br />
                        <Label><b>$ {this.state.accountbalance * this.state.riskpercentage / 100 || 0}</b></Label>
                        <br /><br />
                        <Label>Risk / Unit</Label>
                        <br />
                        <Label><b>{(typeof this.state.risk == "number" ? "$ " : "") + this.state.risk} </b></Label>
                        <br /><br />
                        <Label>Position Size (units)</Label>
                        <br />
                        <Label><b>{this.state.units}</b></Label>
                        <br /><br />
                        <Label>Position Size with Leverage (units)</Label>
                        <br />
                        <Label><b>{this.state.units * this.state.leverage}</b></Label>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FooterLanding />
      </>
    );
  }
}

// export default PositionSizing;
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(['users'])
)(PositionSizing)