import { createStore } from 'redux'
import rootReducer from './reducers/rootReducer'
import { applyMiddleware, compose } from 'redux'
import { getFirebase } from 'react-redux-firebase';
import thunk from 'redux-thunk'

const initialState = {}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default () => {
    return createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(thunk.withExtraArgument({ getFirebase })),
        )    // applyMiddleware(...middleware) // to add other middleware
    )
}