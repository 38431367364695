import React, { Component } from "react";

import { Link } from "react-router-dom";
import { NavItem, NavLink, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { connect } from 'react-redux'
// import { getFirebase } from 'react-redux-firebase';
import { signOut } from 'store/actions/authActions'
// import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'

class SignedInLinks extends Component {
    componentDidMount() {
        //console.log("SignedInLinks mounted")
        // const d = this.props.getUserDetails()
        //console.log(d)
    }
    render() {
        const { auth, signOut } = this.props
        //console.log("SignedInLinks auth", auth)
        return (
            <Nav navbar>
                {/* <NavItem>
            <NavLink to="/courses" tag={Link}>Courses</NavLink>
        </NavItem> */}
                <NavItem>
                    <NavLink target='_blank' href="https://www.swimtrading.com/school" > School</NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2" color="default" caret nav>
                        Tools
                </DropdownToggle>
                    <DropdownMenu className="dropdown-item" right>
                        <DropdownItem to="/positionsizing" tag={Link}>
                            Position Sizing
                  </DropdownItem>
                        {/* <DropdownItem to="/cyclicalchart" tag={Link}>
                    Cyclical Chart
                  </DropdownItem> */}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="mr-2" color="default" nav>
                        {auth.displayName === null ? auth.email : auth.displayName}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-item" right>
                        <DropdownItem to="/account" tag={Link}>
                            Account
                </DropdownItem>
                        <DropdownItem onClick={() => { signOut() }}> Logout</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                {/* <NavItem>
            <NavLink to="/#" tag={Link} onClick={() => { props.signOut() }}> Logout</NavLink>
        </NavItem> */}
            </Nav>
        );

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default compose(
    connect(null, mapDispatchToProps)
)(SignedInLinks)