export default function generateUserCourseDetails(userId, course) {

    const progress = []
    for (var i = 0; i < course.sections.length; i++) {
        const section = course.sections[i]
        for (var j = 0; j < section.items.length; j++) {
            progress.push({
                section: i,
                item: j,
                completed: false,
                itemId: section.items[j].itemId
            })
        }
    }
    const userCourseDetails = {
        userId: userId,
        courseId: course.id,
        progress: progress,
        courseSlug: course.slug
    }
    return userCourseDetails
}
