import React, { Component } from 'react'
import { connect } from 'react-redux'
import { linkSocial } from '../../store/actions/authActions'
import googleicon from "../../assets/img/google-icon.svg"

import { Button, } from "reactstrap";

class SocialLink extends Component {

    handleSubmit = (e) => {
        let socialType = e.target.value
        //console.log("link",e.target,socialType)
        e.preventDefault();
        this.props.linkSocial(socialType)
    }

    render() {
        return (
            <>
                <Button className=" btn-round mr-1" color="facebook" type="submit" value="facebook" onClick={this.handleSubmit}>
                    <i className="fa fa-facebook" /> facebook
                </Button>
                <Button className=" btn-round mr-1 btn-border" color="google" type="submit" value="google" onClick={this.handleSubmit}>
                    <img src={googleicon} alt="google icon" height="12" width="12" value="google" style={{ marginBottom: "4px" }} />{" "}google
                </Button>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        linkSocial: (socialType) => dispatch(linkSocial(socialType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLink)