const initState = {
    authError: null
}

const authReducer = (state = initState, action) => {

    switch (action.type) {
        case 'SIGNUP_SUCCESS':
            //console.log('Signup success')
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            //console.log(action.err)
            return {
                ...state,
                authError: action.err.message
            }
        case 'LOGIN_ERROR':
            //console.log('Login failed')
            //console.log(action.err.message)
            return {
                ...state,
                authError: action.err.message
            }
        case 'LOGIN_SUCCESS':
            //console.log('Login success')
            return {
                ...state,
                authError: null
            }
        case 'SOCIAL_LOGIN_SUCCESS':
            return {
                ...state,
                authError: null
            }
        case 'SOCIAL_LOGIN_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                authError: action.err.message
            }
        case 'SOCIAL_LINK_SUCCESS':
            return {
                ...state,
                authError: null
            }
        case 'SOCIAL_LINK_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                authError: action.err.message
            }
        case 'PASSWORD_RESET_SUCCESS':
            return {
                ...state,
                authError: null,
                resetSuccess:true
            }
        case 'PASSWORD_RESET_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                authError: action.err.message
            }
        case 'LOGOUT_SUCCESS':
            //console.log('Logout success')
            return state
        case 'UPDATE_PROFILE_SUCCESS':
            //console.log('Update Profile Success')
            alert('Successfully Updated Profile')
            return {
                ...state,
                updateError: null,
                updateSuccess: true
            }
        case 'UPDATE_PROFILE_ERROR':
            //console.log(action.err.message)
            return {
                ...state,
                updateError: action.error.message,
                updateSuccess: false
            }
        case 'UPDATE_PASSWORD_SUCCESS':
            //console.log('Update Profile Success')
            alert('Successfully Updated Password')
            return {
                ...state,
                updateError: null,
                updateSuccess: true
            }
        case 'UPDATE_PASSWORD_ERROR':
            //console.log(action)
            return {
                ...state,
                updateError: action.error.message,
                updateSuccess: false
            }
        case 'RESET_AUTH_ERROR':
            return {
                ...state,
                updateError: null,
                authError: null,
                updateSuccess: false,
                resetSuccess:null
            }
        default:
            return state

    }
}

export default authReducer