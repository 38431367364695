import React from 'react'
import { Card, CardBody, CardTitle } from "reactstrap";

import CheckFeaturedImage from '../general/CheckFeaturedImage'
import categoryDetail from '../../functions/categoryDetail'
import decodeHtml from '../../functions/decodeHtml'

const PostSummary = ({ post, category }) => {
    if (post && category) {
        var catName = category.name
        const catColor = categoryDetail(catName, "color")
        return (
            <Card className="card-blog" >
                <div className="card-image">
                    <a href={"/post/" + post.slug}>
                        <CheckFeaturedImage image={post.featuredimage} />
                        {/* {checkFeaturedImage(post.featuredImage)} */}
                    </a>
                </div>
                <CardBody>
                    <h6 className={"card-category text-" + catColor}>{catName}</h6>
                    <CardTitle tag="h5">
                        <a href={"/post/" + post.slug}>{decodeHtml(post.title)}</a>
                    </CardTitle>
                    {/* <p className="card-description" dangerouslySetInnerHTML={{ __html: decodeHtml(post.excerpt) && decodeHtml(post.excerpt).split("\n")[0] }}></p>
                    <hr /> */}
                </CardBody>
            </Card>
        )
    } else {
        return <></>
    }
}
export default PostSummary