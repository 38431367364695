import React from 'react'
import CourseCard from './CourseCard'

// reactstrap components
import { Row, Col, Container } from "reactstrap";

import getUserCourse from 'functions/getUserCourse'
import sortObject from 'functions/sortObject'
const CourseCardBuilder = ({ courses, userCourses, userId, courseType, location }) => {

    if (courses) {
        const sortedCourseList = courseType.courseList ? sortObject(courseType.courseList) : []
        return sortedCourseList.map(({ courseId }, key) => {
            const course = courses[courseId]
            const { userCourse } = getUserCourse(userCourses, userId, courseId)
            return (

                <Col md="6" key={key}>
                    <CourseCard location={location} course={course} userCourse={userCourse} userId={userId} courseId={courseId} />
                </Col>
            )
        })
    }
    else
        return <></>

    // const course = courses[courseId]
    // return (
    //     <CourseCard location={this.props.location} course={course} userCourse={userCourse} userId={userId} courseId={courseId} key={key} />
    // )
}

const CourseSection = (props) => {
    const { courseType } = props
    return (

        <Container className="">
            <Row className="justify-content-md-center">
                <Col className="ml-auto mr-auto" md="10">
                    <h2 className="title text-center">{courseType.title}</h2>
                </Col>
            </Row>
            <Row className="flex-container justify-content-md-center">
                {/* {pageSummary(pages)} */}
                <CourseCardBuilder {...props} />
            </Row>
            {/* -------------------------------------------------- */}
            {/* <Row>
                <Col className="ml-auto mr-auto" md="12">
                    <h2 className="title">{courseType.title}</h2>
                    <CourseCardBuilder {...props} />
                </Col>
            </Row> */}
        </Container>
    )
}
export default CourseSection