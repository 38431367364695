/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterLanding() {
  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="https://www.swimtrading.com/school"
                    className="mr-1"
                  >
                    School
                  </a>
                </li>
                {/* <li>
                  <a
                    href="/courses"
                    className="mr-1"
                  >
                    Courses
                  </a>
                </li> */}
                <li>
                  <a
                    href="/contactus"
                    className="mr-1"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="/privacy"
                    className="mr-1"
                  >
                    Privacy
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()} Swim Trading. All rights reserved.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterLanding;
