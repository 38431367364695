import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import { Form, Button, Row, Col, FormGroup } from "reactstrap";
import { addStripeToken } from 'store/actions/stripeCustomerActions'

class NewCardForm extends Component {
  render() {
    // const stripe = useStripe();
    // const elements = useElements();
    const { stripe, elements, auth, tokenError } = this.props
    const handleSubmit = async (event) => {
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      //console.log(stripe)
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardNumberElement = elements.getElement(CardNumberElement);

      // Use your card Element with other Stripe.js APIs
      // const { error, paymentMethod } = await stripe.createPaymentMethod({
      //   type: 'card',
      //   card: cardNumberElement,
      // });
      const { token } = await stripe.createToken(cardNumberElement);
      //console.log("add stripe customer", token)
      this.props.addStripeToken({ userId: auth.uid, token: token })
      // if (error) {
      //console.log('[error]', error);
      // } else {
      //console.log('[PaymentMethod]', paymentMethod);
      // }
    };

    return (
      <Form onSubmit={handleSubmit}>
        {/* <CardElement /> */}
        <FormGroup>
          <h6>
            Card Number <span className="icon-danger">*</span>
          </h6>
          <CardNumberElement options={{
            classes: {
              base: "form-control"
            },
            style: {
              base: {
                'background-color': '#FFFFFF',
                'border': '1px solid #DDDDDD',
                'border-radius': '4px !important',
                'color': '#66615b',
                'font-size': '14px',
                'transition': 'background-color 0.3s ease 0s',
                'padding-right': '0 !important',
                'padding': '7px 12px',
                'height': '40px',
                'box-shadow': 'none',
              }
            }
          }}
            onChange={(e) => {
              this.setState({ buttonSubmit: !e.empty })
            }} />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <h6>
                Expiry Date <span className="icon-danger">*</span>
              </h6>
              <CardExpiryElement options={{ classes: { base: "form-control" }, }} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <h6>
                CVC <span className="icon-danger">*</span>
              </h6>
              <CardCvcElement options={{ classes: { base: "form-control" }, }} />
            </FormGroup>
          </Col>
        </Row>
        {tokenError ? <p className="text-danger">{tokenError}</p> : null}
        <Button type="submit" disabled={!stripe}>
          Submit
      </Button>
      </Form>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addStripeToken: (props) => dispatch(addStripeToken(props))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(['promocodes'])
)(NewCardForm)