import authReducer from './authReducer'
import postReducer from './postReducer'
import stripeCustomersReducer from './stripeCustomersReducer'
import newsletterReducer from './newsletterReducer'
import userCourseReducer from './userCourseReducer'
import functionReducer from './functionReducer'
import userReducer from './userReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
    auth: authReducer,
    post: postReducer,
    newsletter: newsletterReducer,
    stripeCustomer  : stripeCustomersReducer,
    userCourse: userCourseReducer,
    function: functionReducer,
    user: userReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer