import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

import { Col } from "reactstrap";
import decodeHtml from '../../functions/decodeHtml'

const RelatedPosts = ({ relatedPosts, postId }) => {
    if (!relatedPosts) return <></>
    const displayImage = (imageUrl) => {
        if (imageUrl)
            return <img alt="..." className="img-rounded img-responsive" src={imageUrl} />
        else
            return <></>
    }

    var catPostkeys = Object.keys(relatedPosts).filter((relatedPostkey) => relatedPosts[relatedPostkey].id !== postId)//.splice(0, 3)
    catPostkeys = catPostkeys.sort((a, b) => relatedPosts[b].date.seconds - relatedPosts[a].date.seconds)//.splice(0, 3)
    catPostkeys = catPostkeys.splice(0, 3)
    //console.log(catPostkeys)
    return catPostkeys.map((catPostkey, key) => {
        const post = relatedPosts[catPostkey]
        //console.log(post.date.seconds)
        return (
            <Col md="4" key={post.id}>
                <a href={"/post/" + post.slug}>
                    {displayImage(post.featuredimage)}
                    <p className="title">
                        {decodeHtml(post.title)}
                    </p>
                </a>
            </Col>
        )
    })
}


const mapStateToProps = (state, props) => ({
    relatedPosts: state.firestore.data.relatedPosts,
    firestore: state.firestore
})

export default compose(
    firestoreConnect(({ catId }) => [
        {
            collection: 'posts',
            where: [
                ['categories', 'array-contains', catId],
                ['protected', '==', false]
            ],
            orderBy: ['date', 'desc'],
            limit: 4,
            storeAs: 'relatedPosts'
        }
    ]),
    connect(mapStateToProps)
)(RelatedPosts)