import React from 'react'
import { ElementsConsumer } from '@stripe/react-stripe-js';
import NewCardForm from 'components/Checkout/NewCardForm'

const InjectedNewCardForm = (props) => {

    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <NewCardForm elements={elements} stripe={stripe} {...props} />
            )}
        </ElementsConsumer>
    );
};

export default InjectedNewCardForm