import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

// reactstrap components
import { Button, Form, Input, Container, Row, Col, FormGroup, Label } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import FooterLanding from "components/Footers/FooterLanding.js";
import AccountPanel from "components/SidePanels/AccountPanel"
import SocialLink from "components/auth/SocialLink"

//actions
import { updateProfile } from 'store/actions/authActions'
import { updateMailingList } from 'store/actions/userActions'

class Profile extends Component {
  state = {
    email: '',
    displayName: '',
    mailingLists: {},
    mailingListInitialized: false
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state);
    this.props.updateProfile(this.state);
  }
  handleToggle = (e) => {
    this.setState({
      mailingLists: {
        ...this.state.mailingLists,
        [e.target.id]: e.target.checked
      }
    })
  }
  handleSubmitLists = (e) => {
    e.preventDefault();
    // console.log(this.state)
    this.props.updateMailingList(this.state);
  }

  componentDidMount = () => {
    console.log(this.state.mailingLists)
    const currentUser = this.props.auth;
    this.setState({
      email: currentUser.email,
      displayName: currentUser.displayName,
      uid: currentUser.uid
    })
  }
  componentDidUpdate = () => {
    const { currentUser, auth } = this.props
    const { mailingListInitialized } = this.state
    if (currentUser && auth && currentUser.email === auth.email && mailingListInitialized === false) {
      console.log("Initialize mailing lists", currentUser)
      this.setState({
        mailingLists: currentUser.mailingLists,
        mailingListInitialized: true
      })
    }
  }

  componentWillUnmount = () => {
    this.setState({
      mailingListInitialized: false,
      mailingLists: {}
    })
  }

  refreshPage = () => {
    window.location.reload(true);
  }

  render() {
    const { auth } = this.props
    const { mailingLists } = this.state
    //Route Guard
    if (!auth.uid) return <Redirect to='/' />

    document.documentElement.classList.remove("nav-open");
    const mailingListkeys = Object.keys(mailingLists)
    return (
      <>
        <Navbar fixed={true} />
        <div className="wrapper">
          <div className="main ">
            <div className="section section-gray">
              <Container >
                <Row>
                  <Col md="2" className="setting-panel">
                    <AccountPanel />
                  </Col>
                  <Col md="10" className="setting-content">
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="12">
                        <h2 className="title">My Profile</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto " md="8">
                        <Form onSubmit={this.handleSubmit}>
                          <FormGroup className="row">
                            <Label htmlFor="email" sm="2">Email</Label>
                            <Col sm="10">
                              <Input id="email" placeholder="name" type="text" value={this.state.email} onChange={this.handleChange} />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label htmlFor="name" sm="2">Name</Label>
                            <Col sm="10">
                              <Input id="displayName" placeholder="name" type="text" value={this.state.displayName} onChange={this.handleChange} />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label htmlFor="loginMethods" sm="12">Additional Login Method</Label>
                            <Col sm="10">
                              <SocialLink />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Col className="ml-auto mr-auto " sm="6">
                              <Button block className="btn-round" color="primary">Update</Button>
                            </Col>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="12">
                        <h2 className="title">Subscription</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto " md="8">
                        <Form onSubmit={this.handleSubmitLists}>
                          {mailingListkeys.map((mailingListkey, key) =>
                            <FormGroup className="row" check key={key}>
                              <Label check >
                                <Input defaultChecked={this.state.mailingLists[mailingListkey]} type="checkbox" id={mailingListkey} onChange={this.handleToggle}></Input>
                                {mailingListkey} <span className="form-check-sign"></span>
                              </Label>
                            </FormGroup>
                          )}
                          <FormGroup className="row">
                            <Col className="ml-auto mr-auto " sm="6">
                              <Button block className="btn-round" color="primary">Update</Button>
                            </Col>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <FooterLanding />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updateError: state.auth.updateError,
    updateSuccess: state.auth.updateSuccess,
    updateMailingListSuccess: state.user.updateMailingListSuccess,
    currentUser: state.firestore.data.currentUser,
    auth: state.firebase.auth,
    store: state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (creds) => dispatch(updateProfile(creds)),
    updateMailingList: (values) => dispatch(updateMailingList(values))
  }
}

export default compose(
  firestoreConnect((props) => {
    console.log(props.auth)
    return [{ collection: 'users', doc: props.firebase.auth().W, storeAs: 'currentUser' },]
  }),
  connect(mapStateToProps, mapDispatchToProps))(Profile);
