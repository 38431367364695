import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'

import Navbar from "components/Navbars/Navbar.js";
import SubscribeForm from "components/SubscribeForm/SubscribeForm.js"
import PostList2 from 'components/posts/PostList2'
import FooterLanding from "components/Footers/FooterLanding.js";
// import { academyConfig } from '../config';

class Category extends Component {

  catPosts = (categories, posts, slug) => {
    if (!isLoaded(categories, posts))
      return <></>
    else {
      const catKey = Object.keys(categories)[0]

      return <PostList2 catId={catKey} />
    }
  }
  render() {
    const { categories, posts, match } = this.props

    return (<>
      <Navbar fixed={true} />
      <div className="blog-posts">
        <div className="wrapper">
          <div className="main">
            <div className="blog-2 section">
              {
                this.catPosts(categories, posts, match.params.slug)
              }
            </div>
          </div>
          <SubscribeForm />
        </div>
        <FooterLanding />
      </div>
    </>
    )
  };
}

// export default Academy
const mapStateToProps = (state, props) => ({
  categories: state.firestore.data.categories,
  posts: state.firestore.data.posts,
  firestore: state.firestore
})

export default compose(
  firestoreConnect(({ match }) => [
    { collection: 'categories', where: ['slug', '==', match.params.slug] },
    { collection: 'posts' }
  ]), // or { collection: 'todos' }
  connect(mapStateToProps)
)(Category)