import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function PaymentHeader() {
  return (
    <div className="page-header loading-page" >
    {/* <div className="page-header loading-page" style={{ backgroundImage: "url(" + require("assets/img/cover0.png") + ")" }}> */}
      <div className="filter" />
      <div className="content-center">
        <Container>
          <div className="motto">
            <h1>Payment Processing</h1>
            <h3>Please do not close the browser.</h3>
            <div className="uil-reload-css" >
              <div></div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default PaymentHeader;
