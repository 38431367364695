import React, { Component } from 'react'

// reactstrap components
import { Row, Col, Card } from "reactstrap";

// swimtrading components
import CheckFeaturedImage from '../general/CheckFeaturedImage'
import CourseBody from './CourseBody'

const CourseImage = ({ course }) => {
    return (
        <Col >
            <div className="card-image">
                <a href={course.url}>
                    <CheckFeaturedImage image={course.imageUrl} />
                </a>
            </div>
        </Col>
    )
}

class CourseCard extends Component {

    render() {
        let { course, userCourse } = this.props

        if (course) {
            course = { ...course }
            // course.url = course.url ? course.url : "/course/" + course.slug + "/info"

            course.url = userCourse ?
                "/course/" + course.slug + "/learn" :
                course.url ? course.url : "/course/" + course.slug + "/info"

            const props = { ...this.props, course }
            return (<>
                <Card className="card-product card-plain" >
                    {/* <a href={courseUrl} > */}
                    <Row>
                        <CourseImage course={course} />
                    </Row>
                    <Row>
                        <CourseBody {...props} />
                    </Row>
                    {/* </a> */}

                </Card>

                {/* <Card className="card-plain card-blog text-center">
                <div className="card-image">
                    <a href={page.url}>
                        <CheckFeaturedImage image={page.imageUrl} />
                    </a>
                </div>
                <CardBody>
                    <CardTitle tag="h3">
                        <a href={page.url}>{decodeHtml(page.title)}</a>
                    </CardTitle>
                    <p className="card-description" dangerouslySetInnerHTML={{ __html: decodeHtml(page.description) && decodeHtml(page.description).split("\n")[0] }}></p>
                    <br />
                    <Button className="btn-round" color={color} href={page.url}> Read More </Button>
                </CardBody>
            </Card> */}
            </>
            )
        }
        else
            return <></>
    }
}
export default CourseCard
