import React, { Component } from 'react';
import FooterLanding from 'components/Footers/FooterLanding.js';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import history from '../history';

import Navbar from 'components/Navbars/Navbar.js';

// reactstrap components
import { Container } from 'reactstrap';

import CourseSection from '../components/courses/CourseSection'
import { AuthContext } from '../contexts/AuthContext';
import sortObject from 'functions/sortObject'

class Courses extends Component {
  render() {
    const { courseConfig, courses, userCourses, EnrollSuccess, location } = this.props

    const sortedCourseTypes = courseConfig ? sortObject(courseConfig.courseTypes) : []
    if (EnrollSuccess)
      history.push({
        pathname: '/course/' + EnrollSuccess + '/learn',
        state: { from: location.pathname, resetEnrollFlag: true }
      })
    //<Redirect to={'/course/' + EnrollSuccess + '/learn'} />
    return (<AuthContext.Consumer>
      {(authContext) => {
        const { auth } = authContext;
        const userId = auth ? auth.uid : null
        return (
          <>
            <Navbar fixed={true} />
            {/* <div className='blog-posts'> */}
            <div className='wrapper'>
              <div className='main'>

                <Container>
                  {/* <h2 className='title'>Courses</h2> */}
                  {
                    sortedCourseTypes.map((courseType, key) => {
                      if (courseType.active) {
                        let props = { courseType, userCourses, courses, userId, location }
                        return <CourseSection key={key} {...props} />
                      }
                      else
                        return <div key={key}></div>
                    })
                  }
                  {/* {this.getCourses(courses, userCourses, userId)} */}
                </Container>
              </div>
            </div>
            <FooterLanding />
            {/* </div> */}
          </>)
      }}
    </AuthContext.Consumer>
    )
  };
}

// export default Courses
const mapStateToProps = (state, props) => ({
  courseConfig: state.firestore.data.config ? state.firestore.data.config.courses : null,
  courses: state.firestore.data.courses,
  userCourses: state.firestore.data.userCourses,
  firestore: state.firestore,
  EnrollSuccess: state.userCourse.EnrollSuccess
})

export default compose(
  firestoreConnect((props) => [
    { collection: 'config' },
    { collection: 'courses' },
    { collection: 'userCourses', where: ['userId', '==', props.firebase.auth().W ? props.firebase.auth().W : ''] },
  ]), // or { collection: 'todos' }
  connect(mapStateToProps)
)(Courses)