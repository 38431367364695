export const enrollCourse = (details) => {
    const slug = details.courseSlug
    delete details.courseSlug
    //console.log("enrollCourse", details)
    return (dispatch, getState, { getFirebase }) => {
        //console.log("enroll proccess")
        const firebase = getFirebase();
        const userCourseId = details.userId + '-' + details.courseId
        firebase.firestore().collection('userCourses').doc(userCourseId).set(
            details
        ).then(() => {
            dispatch({ type: 'ENROLL_SUCCESS', slug: slug });
        }).catch((err) => {
            dispatch({ type: 'ENROLL_ERROR', err });
        });

    }
}
export const updateProgress = ({ userCourseId, progress }) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.firestore().collection('userCourses').doc(userCourseId).update({
            progress: progress
        }).then(() => {
            dispatch({ type: 'UPDATE_PROGRESS_SUCCESS' });
        }).catch((err) => {
            dispatch({ type: 'UPDATE_PROGRESS_ERROR', err });
        });

    }
}
export const resetEnroll = () => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: 'RESET_ENROLL' });
    }
}