import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

// core components
import Navbar from "components/Navbars/Navbar.js";
import FooterLanding from "components/Footers/FooterLanding.js";

class PositionSizing extends Component {

  state = {
    accountbalance: 5000,
    riskpercentage: 2,
    risk: 0,
    units: 0,
    entryprice: '',
    stoploss: '',
    leverage: 1,
    direction: 1
  }
  handleChange = (e) => {

    this.setState({
      [e.target.id]: parseFloat(e.target.value)
    }, this.calculateRisk)
  }
  calculateRisk = () => {
    var { entryprice, stoploss, direction } = this.state
    //console.log(this.state)
    if (entryprice && stoploss) {
      var risk = direction * (entryprice - stoploss)
      this.setState({
        risk: risk >= 0 ? risk : "Invalid"
      }, this.calculateUnits)
    } else {
      this.setState({
        risk: 0
      })
    }
  }
  calculateUnits = () => {

    var { accountbalance, risk, riskpercentage } = this.state

    if (accountbalance && risk && riskpercentage) {
      this.setState({
        units: Math.floor(accountbalance * riskpercentage / 100 / risk)
      })
    }
    else
      this.setState({
        units: 0
      })
  }

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  }
  componentWillUnmount() {
    document.body.classList.remove("contact-page");
  }
  render() {

    //Route Guard
    // const { auth } = this.props
    // if (!auth.uid) return <Redirect to='/login' />

    return (
      <>
        <Navbar fixed={true} />
        <div className="main">
          <div className="section section-gray">
            {/* <Container> */}
              {/* <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Market Insights Virtual Summit</h2>
                </Col>
              </Row> */}
              {/* <Row>
                <Col className="ml-auto mr-auto text-center" md="12"> */}
                  <iframe scrolling="no" title="form" id="mailchimpiframe"  src="https://mailchi.mp/db1726e0e207/market-insights"></iframe>
                {/* </Col>
              </Row>
            </Container> */}
          </div>
        </div>
        <FooterLanding />
      </>
    );
  }
}

// export default PositionSizing;
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(['users'])
)(PositionSizing)