import React, { Component } from "react";
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { Helmet } from "react-helmet";
//https://medium.com/@remarkablemark/an-alternative-to-dangerously-set-innerhtml-64a12a7c1f96 

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import FooterLanding from "components/Footers/FooterLanding.js";
import LoadingPageHeader from 'components/Headers/LoadingPageHeader'
import SubscribeForm from "components/SubscribeForm/SubscribeForm.js"
import decodeContent from '../functions/decodeContent'
// import AcademyPanel from 'components/SidePanels/AcademyPanel'
import RelatedPosts from '../components/posts/RelatedPosts'

import Error404 from "views/Error404.js";
import Content from "components/Post/Content.js"

class Post extends Component {

  render() {
    var { posts, match, auth } = this.props


    if (!isLoaded(posts)) {
      return <LoadingPageHeader />
    }

    if (posts === null) return <Error404 />
    const postKey = Object.keys(posts).find((key) => posts[key].slug === match.params.slug)
    const post = posts[postKey]
    var d0 = new Date(post.date.seconds * 1000)
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var date = d0.toLocaleDateString('en-US', options)

    const content = decodeContent(post.content)
    //console.log(postKey)
    const displayImage = (imageUrl) => {
      if (imageUrl)
        return <Card data-radius="none"><div className="card-image"><img src={post.featuredimage} alt="" /></div></Card>
      else
        return <></>
    }
    return (<>
      <Navbar fixed={true} />
      <div className="blog-post">
        <Helmet>
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={decodeContent(post.title)} />
          {/* <meta property="og:description" content="How much does culture influence creative thinking?" /> */}
          <meta property="og:image" content={post.featuredimage} />
          <meta property="fb:app_id" content="272477907053368" />
        </Helmet>
        <div className="wrapper">
          <div className="main">
            <div className="section section-white">
              <Container>
                <Row>
                  <Col md="12">
                    <Row>
                      <Col className="ml-auto mr-auto" md="12">
                        <div className="text-center">
                          <h3 className="title">
                            {decodeContent(post.title)}
                          </h3>
                          <h6 className="title-uppercase">{date}</h6>
                        </div>
                      </Col>
                      <Col className="ml-auto mr-auto" md="10">
                        {displayImage(post.featuredimage)}
                        <div className="article-footer">
                          <Container>
                            <Row>
                              <Col className="mr-auto" md="4">
                                <div className="sharing">
                                  <h5>Spread the word</h5>
                                  <Button className="btn-just-icon mr-1" color="twitter" >
                                    <a href={"https://twitter.com/intent/tweet?text=" + post.title + "&url=" + window.location.href}>
                                      <i className="fa fa-twitter" />
                                    </a>
                                  </Button>
                                  <Button className="btn-just-icon mr-1" color="facebook" >
                                    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href}>
                                      <i className="fa fa-facebook" /></a>
                                  </Button>
                                  <Button className="btn-just-icon mr-1" color="linkedin" >
                                    <a href={"https://www.linkedin.com/shareArticle?url=" + window.location.href + "&title=" + post.title + "&mini=true"}>
                                      <i className="fa fa-linkedin" /></a>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        <hr />
                        <Content content={content} auth={auth} />
                        <br />
                      </Col>

                    </Row>

                  </Col>
                </Row>
                <Row>
                  <div className="related-articles">
                    <h3 className="title">Related articles</h3>
                    <legend />
                    <Container>
                      <Row>
                        <RelatedPosts postId={post.id} catId={post.categories[0]} />
                      </Row>
                    </Container>
                  </div>
                </Row>
              </Container>
            </div>
          </div>
          <SubscribeForm />
        </div>
        <FooterLanding />
      </div>
    </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  posts: state.firestore.data.posts,
  firestore: state.firestore,
  auth: state.firebase.auth
})

export default compose(
  firestoreConnect(({ match }) => [
    {
      collection: 'posts', where: [
        ['slug', '==', match.params.slug],
        ['protected', '==', false]
      ]
    }
  ]),
  connect(mapStateToProps)
)(Post)