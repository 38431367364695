import React, { Component } from "react";
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import history from '../history';
import { enrollCourse } from 'store/actions/userCourseActions'

// core components
import CheckoutNavbar from "components/Navbars/CheckoutNavbar";
import FooterLanding from "components/Footers/FooterLanding.js";
import generateUserCourseDetails from 'functions/generateUserCourseDetails'

class Checkout extends Component {
  render() {

    //Route Guard
    const { auth, location, EnrollSuccess } = this.props
    if (!location.state) return <Redirect to='/' />
    const { product } = location.state

    const userCourseDetails = generateUserCourseDetails(auth.uid, product)
    //console.log("enroll user to course", userCourseDetails)
    enrollCourse(userCourseDetails)
    if (EnrollSuccess) {
      history.push({
        pathname: "/course/" + EnrollSuccess + "/learn",
        state: { from: location.pathname, resetEnrollFlag: true }
      })
    }
    return (<>
      <CheckoutNavbar fixed={true} />
Payment Successful!
      <FooterLanding />
    </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    EnrollSuccess: state.userCourse.EnrollSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    enrollCourse: (creds) => dispatch(enrollCourse(creds))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Checkout)