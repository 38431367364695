import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'

// reactstrap components
import { Row, Col, Container, } from "reactstrap";

import PostSummary from './PostSummary2.js'
import getCatPosts from '../../functions/getCatPosts'
import ReadMore from './ReadMore'

class PostList extends Component {
  render() {
    const { catId, posts, categories, maxPosts, title } = this.props
    if (!isLoaded(categories)) return <></>
    const catPosts = getCatPosts(posts, catId, maxPosts)
    const category = categories[catId]
    const name = title ? title : category.name

    const postSummary = (catPosts, category) => {
      if (catPosts && category)
        return catPosts.map((post, key0) => {
          return (
            <Col md="4" key={key0}>
              <PostSummary post={post} category={category} />
            </Col>
          )
        })
    }

    return (
      <Container className="post-list-2" >
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <a href={"/category/" + category.slug}>
              <h2 className="title text-center">{name}</h2>
            </a>
            <br />
            <Row className="flex-container">
              {postSummary(catPosts, category)}
            </Row>
            <ReadMore maxPosts={maxPosts} category={category} name={name} />
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state, props) => ({
  posts: state.firestore.data.posts,
  categories: state.firestore.data.categories,
  firestore: state.firestore
})

export default compose(
  firestoreConnect(({ catId }) => [
    { collection: 'categories', doc: catId },
    { collection: 'posts', where: ['categories', 'array-contains', catId] },
  ]),
  connect(mapStateToProps)
)(PostList)