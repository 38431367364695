const initState = {}

const functionReducer = (state = initState, action) => {
    switch (action.type) {
        case 'NEW_SUBSCRIBER_SUCCESS':
            console.log('NEW_SUBSCRIBER_SUCCESS')
            return {
                ...state,
                kartraSubscriber: action.result.data
            };
        case 'NEW_SUBSCRIBER_ERROR':
            console.error(action.error.message)
            return {
                ...state,
                kartraSubscriber: action.error
            };
        case 'CONTACTEMAIL_SUCCESS':
            console.log('CONTACTEMAIL_SUCCESS')
            return {
                ...state,
                contactEmailStatus: "Success"
            };
        case 'CONTACTEMAIL_ERROR':
            console.error(action.error.message)
            return {
                ...state,
                contactEmailStatus: "Failed"
            };
        default:
            return state;
    }
}

export default functionReducer