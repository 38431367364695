import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components
import CourseCtaButton from '../../components/courses/CourseCtaButton'

const CourseHeader = (props) => {
  //console.log(props)
  const { course } = props
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("product-page");
    return function cleanup() {
      document.body.classList.remove("product-page");
    };
  });

  if (course) {
    return (
      <>
        {/* require("assets/img/sections/clark-street-mercantile.jpg") */}
        <div
          className="page-header page-header-xs"
          style={{
            backgroundImage:
              "url(" +
              course.imageUrl +
              ")"
          }}
        >
          <div className="filter" />
          <div className="content-center">
            <Container>
              <div className="motto">
                <h1>{course.title}</h1>
                <br />
                <CourseCtaButton size="lg" {...props} />
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default CourseHeader;
